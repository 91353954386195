import React,{useState, useEffect} from 'react'
import styles from './BottomGraphPanel.module.scss'
import HorizontalMultiBarChart from '../../components/HorizontalMultiBarChart'
import HorizontalBarChart from '../../components/HorizontalBarChart'
import toolTipFormatter from './toolTipFormatter'
import dataDisplayOptions from './dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import Modal from '../../components/ModalPopupProfile'
import Icon from '../../components/Icon'
import { CSVLink } from 'react-csv'
import Empty_Vaccine_Rate from '../../assets/images/Empty_Vaccine_Rate.png';
const BottomGraphPanel = ({ getfilterts }) => {  

  const [getData, setData] = useState('')

  var fluseasonValue = getfilterts.season
  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false)
  const { showAddsubscribePop, setShowAddsubscribePop } = useGeoState()
  const { vulCount } = useGeoState()
  const onUnsubscribeClick = () => {
    
    setShowUnsubscribeDialog(true)
  }

  const onUnsubscribeCancelClick = () => {
    setShowUnsubscribeDialog(false)  
  }
  debugger
  const { data, error } = useGeoFetch('', '', 'FluVaccine',  `VaccinationRate?lob=${getfilterts.lob}&type=${getfilterts.type}&fluseason=${getfilterts.season}&month=${getfilterts.month}&fluFolder=VaccinationRate` )
  //const { data, error } = useGeoFetch('', '', 'FluVaccine', 'VaccinationRate',  getfilterts, 'VaccinationRate',getfilterts.season, getfilterts.month, getfilterts.lob , getfilterts.type)
  useEffect(() => {
    if (data !== undefined || data === null) {
      if (data.response_code === 200) {       
        setData(JSON.parse(data.response_data))
      } 
      // else {
      //   setErrorCode(filterData.response_code)
      //   setErrorMessage(filterData.response_message)
      //   setErrorSubject(filterData.response_subject)
      //   seterrorOccured(true)
      // }
    }
  }, [data])

  return   (
    <div className={styles.base}>
      {(fluseasonValue === '10' || fluseasonValue === '11') ?
      <>
        <div className={styles.ratePanel}>
          <span>
            State Vaccination Rate
            <span>
            Patients Count: <strong>{vulCount}</strong>


                <span className={styles.export} >
                  <CSVLink
                    data={getData}
                    filename={'Vaccination_Rate.csv'}
                    className="styles.export"
                    target="_blank"
                    style={{
                      color: '#424242',
                      fontFamily: 'graphik',
                      fontSize: '14px',
                    }}
                  >
                    Export this Data with LOB
                    <span style={{ marginLeft: '5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                      <path d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z" fill="#424242" />
                    </svg> </span>
                  </CSVLink>
                </span>
          </span>
          </span>         
          <HorizontalMultiBarChart
            toolTipFormatter={toolTipFormatter}
            entity="FluVaccine"
            location="VaccinationRate"
            options={stateOptions}
            dataDisplayOptions={dataDisplayOptions}
            filterId={getfilterts}
            fluFolder='VaccinationRate'
            fluseason={getfilterts.season}
            month={getfilterts.month}
            lob={getfilterts.lob}
            type={getfilterts.type}
            show=''
          />

          <span className={styles.graphSource}>
            Source : <span>Medical and Pharmacy Claims Data</span>
            <span style={{marginLeft:'185px', fontSize:'12px', cursor:'pointer'}} ><a onClick={onUnsubscribeClick}>Load More States</a></span>
          </span>
        
        </div>

          <div>
            <Modal
              open={showUnsubscribeDialog}
              onClose={() => setShowUnsubscribeDialog(false)}
              margin='60px'
              setWidth='80vw'
              setHeight='0vw'
              display=''
            >
              <button
                noValidate
                style={{ marginLeft: '100%' }}
                onClick={onUnsubscribeCancelClick}
              >
                <Icon type="remove" size={'1.0rem'} />
              </button>
              <div className={styles.modal} style={{overflowY:'scroll', height:'500px'}}>               
              <HorizontalMultiBarChart
                toolTipFormatter={toolTipFormatter}
                entity="FluVaccine"
                location="VaccinationRate"
                options={stateOptions}
                dataDisplayOptions={dataDisplayOptions}
                filterId={getfilterts}
                fluFolder='VaccinationRate'
                fluseason={getfilterts.season}
                month={getfilterts.month}
                lob={getfilterts.lob}
                type={getfilterts.type}
                show="all"
          />
                
                </div>
            </Modal>

          </div>
</>
 :
 <div className={styles.ratePanel}>
 <span>
   State Vaccination Rate
 </span>
 <div>
   <div style={{textAlign:'center'}}>
   
   <img  src={Empty_Vaccine_Rate}  />
   </div>
   <span style={{fontSize: "15px"}} >
   Please select either 2022-2023 or 2023-2024 to view State wise Vaccination Rates
   </span>
 </div>

</div> 
       
       
      }
      {/* <div className={styles.healthPanel}>
        <span>
          Top Health Care Plans
          <span>
            Patients Count <strong>6.5M</strong>
          </span>
        </span>
        <HorizontalBarChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="PopularProduct"
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart1"
          //filterid="PopularProduct-LOB-All_VTYPE-All_FLUSEASON-10_MONTH-All"
          height='400'
          filterId={getfilterts}
          fluFolder='PopularProduct'
          fluseason={getfilterts.season}
          month={getfilterts.month}
          lob={getfilterts.lob}
          type={getfilterts.type}
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
      </div> */}

 {fluseasonValue !== '10' &&  fluseasonValue !== '11' ?

      <div>      
        <div className={styles.rateList}>
        <span>
          State Vaccination List
          <span>
            Patients Count <strong>{vulCount}</strong>
          </span>
        </span>
        <HorizontalBarChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="StateWise"
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chart1"
          height='400'
          filterId={getfilterts}
          fluFolder='StateWise'
          fluseason={getfilterts.season}
          month={getfilterts.month}
          lob={getfilterts.lob}
          type={getfilterts.type}
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
        </div>
      </div>
 : (
  null
)}
    <div className={styles.rateList}>

        <span>
          Top Health Care Plans
          <span>
            Patients Count: <strong>{vulCount}</strong>
          </span>
        </span>
        <HorizontalBarChart
          toolTipFormatter={toolTipFormatter}
          entity="FluVaccine"
          location="PopularProduct"
          options={stateOptions}
          dataDisplayOptions={dataDisplayOptions}
          chartName="chartPopularProd"
          //filterid="PopularProduct-LOB-All_VTYPE-All_FLUSEASON-10_MONTH-All"
          height='400'
          filterId={getfilterts}
          fluFolder='PopularProduct'
          fluseason={getfilterts.season}
          month={getfilterts.month}
          lob={getfilterts.lob}
          type={getfilterts.type}
        />
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
        </div>
 
    </div>
  )


  
  
}

export default BottomGraphPanel
