import React, { useState, useEffect } from 'react'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

import Content from '../../components/Content'
import Layout from '../../components/Layout'
import SystemNotifications from './SystemNotifications'
import CovidMap from './CovidMap'
import FluMap from './FluMap'
import HrrMap from './HRRMap'
import BottomGraphPanel from './BottomGraphPanel'
import RiskPredictorPanel from './RiskPredictorPanel'
import EmptyMap from '../../components/Map/emptyMap'
import styles from './Dashboard.module.scss'
import DashboardHeader from './DashboardHeader'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'

import * as htmlToImage from 'html-to-image';
const ImageGenerate = () => {
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const hrrnatsdate = dayjs().day(-3).format('YYYY-MM-DD')
  const coviddate = dayjs().day(0).format('YYYY-MM-DD')
  const [showResults, setShowResults] = useState(false)
  const history = useHistory()
  const [getStateJsonFile, setStateJsonFile] = useState();
  var path='static'
  if (process.env.REACT_APP_UI_HOME.includes("local"))
  {
    var path=''
  }
  const appurlhrr = `${process.env.REACT_APP_UI_HOME}/${path}/hrrDashboardMap.png`
  const appurlcovid = `${process.env.REACT_APP_UI_HOME}/${path}/covidDashboardMap.png`
  const appurlflu = `${process.env.REACT_APP_UI_HOME}/${path}/fluDashboardMap.png`
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data)
        setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [1]);


  const [getfilterts, filtersObj] = useState({
    type: 'All',
    lob: 'All',
    season: '11',
    month: 'All',
  })
  const [getMemberVaccinated, setMemberVaccinated] = useState({
    MemberVaccinated: '0M',
  })


  const [natsCovid, setNatsCovid] = useState(undefined)
  const [natsHrr, setNatsHrr] = useState(undefined)

  const { data: covidNatsData, error: covidNatsDataError } = useGeoFetch(
    coviddate,
    coviddate,
    'covid',
    'NAT'
  )

  useEffect(() => {
    if (natsCovid !== undefined) {
      if (covidNatsData !== undefined && covidNatsData !== null) {

        setNatsCovid(covidNatsData)

      }
    }
  }, [covidNatsData])

  const { data: hrrNatsData, error: hrrNatsDataError } = useGeoFetch(
    hrrnatsdate,
    hrrnatsdate,
    'covid',
    'hospitalstats/NAT?identifier=NAT'
  )

  useEffect(() => {
    if (hrrNatsData !== undefined) {

      setNatsHrr(hrrNatsData)
    }
  }, [hrrNatsData])

  const { user } = useAuthState()

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Dashboard',
    indicator_name: 'Dashboard',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }

  useEffect(() => {
    activityLog(logObject)
  }, [1])
  const [getJsonData, setJsonData] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/us_hrr_values.json`
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data)
        setJsonData(data);
      })
      .catch(function (err) {
        console.log(err, " error file load us_hrr_values ");
      });
  }, [1]);




  if (covidNatsDataError || hrrNatsDataError || errorOccured) {
    return (
      <Layout>
        <Content>
          {/* <ErrorMessage
            errorCode={errorCode}
            errorMessage={errorMessage}
            errorSubject={errorSubject}
          /> */}
        </Content>
      </Layout>
    )
  }

  const SaveImageHrr = () => {    
    const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
    const saveImageApiUrl = `${clientConfigApiDomain}/flumeter/saveimage`
    htmlToImage.toPng(document.getElementById('hrrMap'))
      .then(function (imageData) {

        var getImgObject = { name: "hrrDashboardMap.png", base64Content: imageData };
        //console.log(imageData)
        fetch(saveImageApiUrl, { method: 'post', body: JSON.stringify(getImgObject) })
          .then((response) => {
            if (!response.ok) throw Error('Upload failed')
            response.json().then((responseBody) => {
            })
          })
          .catch(() => {
            console.log('Catch Error');
          })
      });
  }

  const SaveImageCovid = () => {    
    const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
    const saveImageApiUrl = `${clientConfigApiDomain}/flumeter/saveimage`
    htmlToImage.toPng(document.getElementById('covidMap'))
      .then(function (imageData) {

        var getImgObject = { name: "covidDashboardMap.png", base64Content: imageData };
        //console.log(imageData)
        fetch(saveImageApiUrl, { method: 'post', body: JSON.stringify(getImgObject) })
          .then((response) => {
            if (!response.ok) throw Error('Upload failed')
            response.json().then((responseBody) => {
            })
          })
          .catch(() => {
            console.log('Catch Error');
          })
      });
  }
  const SaveImageFlu = () => {    
    const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
    const saveImageApiUrl = `${clientConfigApiDomain}/flumeter/saveimage`
    htmlToImage.toPng(document.getElementById('fluMap'))
      .then(function (imageData) {

        var getImgObject = { name: "fluDashboardMap.png", base64Content: imageData };
        //console.log(imageData)
        fetch(saveImageApiUrl, { method: 'post', body: JSON.stringify(getImgObject) })
          .then((response) => {
            if (!response.ok) throw Error('Upload failed')
            response.json().then((responseBody) => {
            })
          })
          .catch(() => {
            console.log('Catch Error');
          })
      });
  }


  const showimage = () => {
    setShowResults(true)
  }

  const redirect = () => {
    history.push('forecasts/hrrmap')
  }

  return (
    <Layout>
      <Content>
        <SystemNotifications />
        <DashboardHeader />
        <div className={styles.base}>

          <div className={styles.fluPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span className={styles.mapName}>Influenza Level</span>
                </div>
              </div>
              {getStateJsonFile ? (                
                <>
                {showResults ? <div id="fluMap">
                <FluMap staticStateJson={getStateJsonFile} />
                </div> : <img onClick={redirect} style={{ cursor: 'pointer' }} width="100%" height="70%" src={appurlflu} />
                }
              </>
              ) : (
                <EmptyMap stateJson={getStateJsonFile} mapHeight={'40vh'} />
              )}
              <div
                style={{ position: 'absolute', bottom: '0px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>Medical Claims Data</span>
                </span>

              </div>
            </div>
          </div>
          <div className={styles.covidPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span className={styles.mapName}>
                    COVID-19 Weekly Cases & Deaths{' '}
                  </span>
                </div>
              </div>
              {getStateJsonFile ? (
                <>
                  {showResults ? <div id="covidMap">
                    {/* <CovidMap staticStateJson={getStateJsonFile} /> */}
                    <img onClick={redirect} style={{ cursor: 'pointer' }} width="100%" height="70%" src={appurlcovid} />
                  </div> : <img onClick={redirect} style={{ cursor: 'pointer' }} width="100%" height="70%" src={appurlcovid} />
                  }
                </>
              ) : (
                <EmptyMap stateJson={getStateJsonFile} mapHeight={'40vh'} />
              )}
              <div
                style={{ position: 'absolute', bottom: '0px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>JHU data</span>
                </span>

              </div>
            </div>
          </div>

          <div className={styles.hrrPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span>COVID-19 Weekly Hospitalizations</span>
                </div>
              </div>

              {getJsonData ? (
                <>
                  {showResults ? <div id="hrrMap">
                    <HrrMap hrrdata={getJsonData} staticStateJson={getStateJsonFile} />
                  </div> : <img onClick={redirect} style={{ cursor: 'pointer' }} width="100%" height="70%" src={appurlhrr} />
                  }
                </>
              ) : (
                <EmptyMap stateJson={getStateJsonFile} mapHeight={'40vh'} />
              )}
              <div
                style={{ position: 'absolute', bottom: '0px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>Health and Human Services</span>
                </span>

              </div>
            </div>
          </div>
        </div>
        <RiskPredictorPanel />

        <BottomGraphPanel
          getMemberVaccinated={getMemberVaccinated}
          getfilterts={getfilterts}
        />

        <button id="btnSaveHrrImage" style={{ border: 'none', color: 'white', backgroundColor: 'white', cursor: 'pointer' }} onClick={SaveImageHrr}>
          AddHrr
        </button> &nbsp;
        <button id="btnSaveCovidImage" style={{ border: 'none', color: 'white', backgroundColor: 'white', cursor: 'pointer' }} onClick={SaveImageCovid}>
          AddCovid
        </button> &nbsp;
        <button id="btnSaveFluImage" style={{ border: 'none', color: 'white', backgroundColor: 'white', cursor: 'pointer' }} onClick={SaveImageFlu}>
          AddFlu
        </button> &nbsp;
        <button id="btnSaveImage" style={{ border: 'none', color: 'white', backgroundColor: 'white', cursor: 'pointer' }} onClick={showimage}>
          show
        </button>
      </Content>
    </Layout>
  )
}

export default ImageGenerate
