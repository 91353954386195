import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthState } from '../../../../components/AuthProvider'
import { useGeoState, useGeoFetch } from '../../../../components/GeoProvider'
import ErrorMessage from '../../../../components/Layout/ErrorMessage'
import Spinner from '../../../../components/Loader/Spinner'
import MapOptions from '../../../../components/AccuracyMapOptions'
import EmptyMap from '../../../../components/Map/emptyMap'
import Map from '../../../../components/Map'

import regionDisplayOptions from './regionDisplayOptions'
import dataDisplayOptions from './dataDisplayOptions'
// import dataDisplayOptionsHRR from '../../../../pages/Forecasts/HRR/dataDisplayOptions'
import toolTipFormatter from './toolTipFormatter'
import toolTipFormatterHRR from './toolTipFormatterHRR'
import FluDetailedView from './FluDetailedView'
import CovidDetailsComponent from './CovidDetailsComponent'
import HRRDetailedView from './HRRDetailedView'
import CountyStats from './CountyStats'
import StateStats from './StateStats'
import CovidStateStats from './CovidStateStats'
import CovidHRRMap from '../../../../pages/Forecasts/HRR/CovidHRRMap'
import CbsaStats from './CbsaStats'
import modelOptions from './fluModelOptions'
import diseaseTypeOptions from './diseaseTypeOptions'
import HrrStats from './HrrStats'
import styles from './FluAccuracyMap.module.scss'
import style from './FluAccuracy.module.scss'
import activityLog from '../../../../utils/activityLog'
import MapLegend from './mapLegend'
import HrrComponent from './HrrComponent'
import CovidComponent from './CovidComponent'
import HrrErrorMetricsComponent from './HrrErrorMetricsComponent'
import FluMsaComponent from './FluMsaComponent'
import CountyComponent from './CountyComponent'
const FluAccuracyMap = ({stateJson}) => {
  const { user } = useAuthState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const entity = 'flu'

  const location = useLocation()
  const hash = location.hash

  const [states, setStates] = useState()
  const [metros, setMetros] = useState()
  const [countiesData, setCountiesData] = useState()
  const [publicMsas, setPublicMsas] = useState()
  const [hrrData, setHRRsData] = useState()  

  const [fluActiveCBSA, setFluActiveCBSA] = useState()
  const [fluStatesCombined, setFluStatesCombined] = useState()
  const [fluMetroCombined, setFluMetroCombined] = useState()

  const [covidStatesData, setCovidStatesData] = useState()
  const [covidErrorMsaData, setCovidErrorMsaData] = useState()
  const [covidErrorCountyData, setCovidErrorCountyData] = useState()
  const [covidErrorStatesData, setCovidErrorStatesData] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
    diseaseSelected,
    // dataSelected,
    setHrrEndDate,
  } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }


  const [cbsaJsonFile, setCbsaJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setCbsaJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
      });
  }, [1]);
  const [getJsonData, setJsonData] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/us_hrr_values.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setJsonData(data);
      })
      .catch(function (err) {
        console.log(err, " error file load us_hrr_values ");
      });
  }, [1]);
  const [getCountyJsonFile, setCountyJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_county_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setCountyJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_county_20m_wkid102004 ");
      });
  }, [1]);
  

  // States Error Metric Data
  const { data: statesData, error: stateDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'errormetric/state?model=' + modelOptions[0].value
  )
  useEffect(() => {
    if (statesData !== undefined || statesData === null) {
      if (statesData.response_code === 200) {
        setStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'activecbsa?areaType=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== undefined || activeCbsasData === null) {
      if (activeCbsasData.response_code === 200) {
        setFluActiveCBSA(activeCbsasData.response_data)
      } else {
        setErrorCode(activeCbsasData.response_code)
        setErrorMessage(activeCbsasData.response_message)
        setErrorSubject(activeCbsasData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [activeCbsasData])

  // State data for right side graph view
  const { data: statesCombinedData, error: statesCombinedDataError } =
    useGeoFetch(
      dataStartDate,
      dataEndDate,
      entity,
      'accuracyreport/state?model=' + modelOptions[0].value
    )

  useEffect(() => {
    if (statesCombinedData !== undefined || statesCombinedData === null) {
      if (statesCombinedData.response_code === 200) {
        setFluStatesCombined(statesCombinedData.response_data)
      } else {
        setErrorCode(statesCombinedData.response_code)
        setErrorMessage(statesCombinedData.response_message)
        setErrorSubject(statesCombinedData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesCombinedData])

  


  // MSA Data
  const { data: msaData, error: msaDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'covid',
    'accuracyreport/msa'
  )

  useEffect(() => {
    if (msaData !== undefined || msaData === null) {
      if (msaData.response_code === 200) {
        setPublicMsas(msaData.response_data)
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  
  // console.log(errMsaData)
  const { data: errStateData, error: errStateDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'covid',
    'errormetric/state'
  )
  useEffect(() => {
    if (errStateData !== undefined || errStateData === null) {
      if (errStateData.response_code === 200) {
        setCovidErrorStatesData(errStateData.response_data)
      } else {
        setErrorCode(errStateData.response_code)
        setErrorMessage(errStateData.response_message)
        setErrorSubject(errStateData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [errStateData])
 


  const sethrrdata = (hrrDataResponse) => {
    setHRRsData(hrrDataResponse);
  };

  const setfluStatsdata = (fluStats) => {
    setMetros(fluStats);
  };
  
  const setCovidData = (covid) => {
    setCovidStatesData(covid);
  };
  const setcountyData = (county) => {
    setCountiesData(county);
  };
  
  const setFluMetroCombineData = (fluMetroComb) => {
    setFluMetroCombined(fluMetroComb);
  };

  const setcountyErrorData = (countyerror) => {
    setCovidErrorCountyData(countyerror);
  };
  
  if (
    activeCbsasDataError ||
    stateDataError ||              
    errStateDataError ||    
    msaDataError ||        
    statesCombinedDataError ||  
    errorOccured
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
      />
    )
  }
  return (
    <div className={style.base}>
      <div className={style.header}>
        <div className={style.content}>
          <div className={`${styles.mapoptionPanel}`}>
            <MapOptions
              regionDisplayOptions={regionDisplayOptions}
              dataDisplayOptions={dataDisplayOptions}
              diseaseTypeOptions={diseaseTypeOptions}
              mapType="CIACCURACY"
            />
          </div>
        </div>
      </div>
      <div className={style.body}>
        <div className={`${styles.base}`}>
          <div className={`${styles.map}`}>          
            {regionSelected === 'state' && diseaseSelected !== 'covid' && (
              <>
                 <MapLegend/>
                {states !== undefined ? (
                  <Map
                    toolTipFormatter={toolTipFormatter}
                    activeCbsasData={null}
                    statesData={states}
                    cbsaData={null}
                    mapOptions={mapOptions}
                    dynamicMapTitle={true}
                    mapType="CIACCURACY"
                    enableButtons={true}
                    stateJson={stateJson}
                  />
                ) : (
                  <EmptyMap  stateJson={stateJson}/>
                )}
              </>
            )}

            {regionSelected === 'state' && diseaseSelected === 'covid' && (
             <CovidComponent stateJson={stateJson} covidData={setCovidData} />
            )}

            {regionSelected === 'hrr' && diseaseSelected === 'covid' && getJsonData && (
              <HrrComponent stateJson={stateJson} hrrData={sethrrdata} />
            )}

            {regionSelected === 'msa' && diseaseSelected === 'covid' && (
              <>
                 <MapLegend/>
                {publicMsas !== undefined && cbsaJsonFile ? (
                  <Map
                    // toolTipFormatter={mapOptions['toolTip']}
                    toolTipFormatter={toolTipFormatter}
                    activeCbsasData={fluActiveCBSA}
                    statesData={covidStatesData}
                    cbsaData={publicMsas}
                    //msaData={publicMsas}
                    mapOptions={mapOptions}
                    dynamicMapTitle={true}
                    mapType="COVIDACC"
                    enableButtons={true}
                    stateJson={stateJson}
                    cbsaJson={cbsaJsonFile}
                  />
                ) : (
                  <EmptyMap />
                )}
              </>
            )}
            
            { diseaseSelected === 'covid' && (
              <CountyComponent countyJson={getCountyJsonFile}  countyResponseData={setcountyData} covidErrorCountyData={setcountyErrorData}/>
            )}

            {regionSelected === 'msa' && diseaseSelected === 'flu' && (          
            <FluMsaComponent modelOptions={modelOptions} stateJson={stateJson} cbsaJson={cbsaJsonFile}
            activeCbsasData={fluActiveCBSA}
            states={states}
            returnErrormetric={setfluStatsdata}
            fluMetrocombinedata={setFluMetroCombineData}
             />
            )}
          </div>
          <div className={`${styles.cbsasPanel}`}>
            {hash &&
            hash !== '#accuracy' &&
            diseaseSelected !== 'covid' && cbsaJsonFile && 
            regionSelected !== 'hrr' ? (
              <FluDetailedView
                cbsaData={metros}
                statesData={states}
                cbsaGraphData={fluMetroCombined}
                stateGraphData={fluStatesCombined}
                hash={hash}              
                cbsaJson={cbsaJsonFile}
               
              />
            ) : null}

            {hash &&
            hash !== '#accuracy' &&
            diseaseSelected === 'covid' &&
            regionSelected !== 'hrr' && cbsaJsonFile? (
              <CovidDetailsComponent
                cbsaData={null}
                statesData={covidErrorStatesData}              
                countyData={covidErrorCountyData}
                cbsaGraphData={msaData}
                stateGraphData={covidStatesData}
                hash={hash}                
                cbsaJson={cbsaJsonFile}
                countyJson={getCountyJsonFile}
              />
            ) : null}

            {hash &&
         
            hash !== '#accuracy' &&
            diseaseSelected === 'covid' &&
            regionSelected == 'hrr' && getJsonData ? (
             
              <HrrErrorMetricsComponent hash={hash} getJsonData={getJsonData}/>
            
            ) : null}
            <>
              {!hash.includes('#state') && regionSelected === 'state' && (
                <>
                  {states !== undefined && cbsaJsonFile? (
                    <>
                      {covidStatesData !== undefined &&
                      diseaseSelected === 'covid' ? (
                        <CovidStateStats
                          stateData={covidStatesData}
                          mapOptions={mapOptions}
                          cbsaJson={cbsaJsonFile}
                          
                        />
                      ) : (
                        <StateStats
                          stateData={states}
                          mapOptions={mapOptions}
                          cbsaJson={cbsaJsonFile}
                         
                        />
                      )}
                    </>
                  ) : (
                    <Spinner />
                  )}
                </>
              )}

              {regionSelected === 'hrr' && !hash.includes('#hrr') && (
                <>
                  {hrrData !== undefined && getJsonData? (
                    <HrrStats hrrMapJson={getJsonData} cbsaData={hrrData} mapOptions={mapOptions} />
                  ) : (
                    <Spinner />
                  )}
                </>
              )}

              {regionSelected === 'msa' &&
                !hash.includes('#msa') &&
                diseaseSelected === 'flu' && (
                  <>
                    {metros !== undefined && cbsaJsonFile ?  (
                      <CbsaStats cbsaJsonFile={cbsaJsonFile} cbsaData={metros} mapOptions={mapOptions} />
                    ) : (
                      <Spinner />
                    )}
                  </>
                )}
              {!hash.includes('#county') &&
                regionSelected === 'county' &&
                diseaseSelected === 'covid' && (
                  <>
                    {countiesData !== undefined && getCountyJsonFile? <CountyStats stateJson={stateJson} countyJson={getCountyJsonFile}/> : <Spinner />}
                  </>
                )}
              {!hash.includes('#msa') &&
                regionSelected === 'msa' &&
                diseaseSelected === 'covid' && (
                  <>
                    {publicMsas !== undefined && cbsaJsonFile ? (
                      <CbsaStats
                        cbsaData={publicMsas}
                        mapOptions={mapOptions}
                        cbsaJsonFile={cbsaJsonFile}
                        mapType="COVIDACC"
                      />
                    ) : (
                      
                      <Spinner />
                    )}
                  </>
                )}
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FluAccuracyMap
