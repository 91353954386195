import React, { useState, useEffect } from 'react'
import { useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import LinkButton from '../../../components/LinkButton'
import Spinner from '../../../components/Loader/Spinner'

import CovidMap from './DashboardMap'
import FluMap from './FluMap'
import HrrMap from './HRRMap'

import styles from './Dashboard.module.scss'
import KpisValues from './KpisValues'
import DashboardHeader from './DashboardHeader'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'
import { startDate, endDate, displayDate } from '../../CovidPharma/dates'
import GeoProvider from '../../../components/GeoProvider'
import dataDisplayOptions from '../../CovidPharma/dataDisplayOptions'

const ForecastDashboard = () => {
  const hrrdate = dayjs().day(-3).format('YYYY-MM-DD') 
  const coviddate = dayjs().day(-1).format('YYYY-MM-DD')
  const fludate = dayjs().day(-1).format('YYYY-MM-DD')
  const history = useHistory()

  const [fluNats, setFluNats] = useState(undefined)
  const [covidNats, setCovidNats] = useState(undefined)
  const [hrrNats, setHrrNats] = useState(undefined)
  const [getStateJsonFile, setStateJsonFile] = useState();
  var path = 'static'
  if (process.env.REACT_APP_UI_HOME.includes("local")) {
    var path = ''
  }

  const { data: natsFluData, error: natsFluDataError } = useGeoFetch(
    fludate,
    fludate,
    'flu',
    'NAT'
  )

  useEffect(() => {
    if (natsFluData !== undefined && natsFluData !== null) {
      if (natsFluData.response_code === 200) {
        setFluNats(natsFluData.response_data)
      }
    }
  }, [natsFluData])

  const { data: natsCovidData, error: natsCovidDataError } = useGeoFetch(
    coviddate,
    coviddate,
    'covidMap',
    'NAT?geo_parent=US',
    true
  )

  useEffect(() => {
    if (natsCovidData !== undefined) {
      if (natsCovidData.response_code === 200) {
        setCovidNats(natsCovidData.response_data)
      }
    }
  }, [natsCovidData])

  const { data: natsHrrData, error: natsHrrDataError } = useGeoFetch(
    hrrdate,
    hrrdate,
    'covid',
    'hospitalstats/NAT?identifier=NAT'
  )

  const { user } = useAuthState()
  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Forecast Home',
    indicator_name: 'Forecast Home',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  
  useEffect(() => {
    activityLog(logObject)
    if (natsHrrData !== undefined) {
      if (natsHrrData.response_code === 200) {
        setHrrNats(natsHrrData.response_data)
      }
    }
  }, [natsHrrData])


  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data)
        setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [1]);
 

  return (
    
    <Layout>
     
        <Content>
        <DashboardHeader />
       
        <div className={styles.base}>
          <div className={styles.fluPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span className={styles.mapName}>Influenza Level</span>
                </div>
                <div className={styles.mapHeaderValue}>
                  <LinkButton
                    className={styles.btn}
                    onClick={() => {
                      history.push(`/Forecasts/flumap`)
                    }}
                  >
                    View More
                  </LinkButton>
                </div>
              </div>

              <FluMap staticStateJson={getStateJsonFile}/>
             
              <div className={styles.sourceDiv}>
                <span className={styles.graphSource}>
                  Source : <span>Medical Claims Data</span>
                </span>
               
              </div>
              <hr style={{ height: '1px', background: '#DADBDC' }} />
              <div className={styles.kpis}>
                {fluNats ? (
                  <KpisValues dataValues={fluNats} mapType="FLU"></KpisValues>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
          <div className={styles.covidPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span className={styles.mapName}>
                    COVID Incidence
                  </span>
                </div>
                <div className={styles.mapHeaderValue}>
                  <LinkButton
                    className={styles.btn}
                    onClick={() => {
                      history.push(`/Forecasts/CovidDashboard`)
                    }}
                  >
                    View More
                  </LinkButton>
                </div>
              </div>
             
              <GeoProvider startDate={startDate}
                  endDate={endDate}
                  displayDate={displayDate}
                  mapOptions={dataDisplayOptions[0]['options'][0]}
                  indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
                >
                  <CovidMap/>
                </GeoProvider>


              <div className={styles.sourceDiv}>
                <span className={styles.graphSource}>
                  Source : <span>UDW data</span>
                </span>
              
              </div>
              <hr style={{ height: '1px', background: '#DADBDC' }} />
              <div className={styles.kpis}>
                {covidNats ? (
                  <KpisValues
                    dataValues={covidNats}
                    mapType="COVID"
                  ></KpisValues>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
          <div className={styles.hrrPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span>COVID-19 Weekly Hospitalizations</span>
                </div>
                <div className={styles.mapHeaderValue}>
                  <LinkButton
                    className={styles.btn}
                    onClick={() => {
                      history.push(`/Forecasts/hrrmap`)
                    }}
                  >
                    View More
                  </LinkButton>
                </div>
              </div>
              <HrrMap />
              <div className={styles.sourceDiv}>
                <span className={styles.graphSource}>
                  Source : <span>Health and Human Services</span>
                </span>               
              </div>
              <hr style={{ height: '1px', background: '#DADBDC' }} />
              <div className={styles.kpis}>
                {hrrNats ? (
                  <KpisValues dataValues={hrrNats} mapType="HRR"></KpisValues>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
        </div>      
      </Content>
      
    </Layout>

  
  )
}
export default ForecastDashboard
