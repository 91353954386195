import React, { useState, useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
//import ErrorMessage from '../../../components/Layout/ErrorMessage'
import Spinner from '../../../components/Loader/Spinner'
import moment from 'moment'
import DashboardHeader from './DashboardHeader'
import Filter from './Filter'
import LineGraph from './LineChart'
import FluIndicatorTable from './FluIndicatorTable'
import { startDate, endDate } from '../dates'
const RespiratorySurveillance = () => {
  //  const [errorCode, setErrorCode] = useState()
  //  const [errorMessage, setErrorMessage] = useState()
  //  const [errorSubject, setErrorSubject] = useState()
  //  const [errorOccured, seterrorOccured] = useState(false)
  const entity = 'surveillance'
  const [nats, setNATs] = useState()
  const [natsAll, setAllNATs] = useState()
  const { fromDate, toDate, locationCode, locationType } = useGeoState()

  const { data: natsData, error: natDataError } = useGeoFetch(
    moment(fromDate).format('YYYY-MM-DD'),
    moment(toDate).format('YYYY-MM-DD'),
    entity,
    locationType + '/Respiratory?identifier=' + locationCode
  )

  useEffect(() => {
    if (natsData !== undefined) {
     
        setNATs(natsData)
     
    }
  }, [natsData])

  const { data: natsAllData, error: natAllDataError } = useGeoFetch(
    startDate,
    endDate,
    entity,
    'NAT/Respiratory?identifier=NAT'
  )

  useEffect(() => {
    if (natsAllData !== undefined) {
    
        setAllNATs(natsAllData)
      
    }
  }, [natsAllData])

  // if (natsData !== undefined) {
  //   var lastDate = Object.keys(natsData).pop()
  //   setLastWeekEndDate(lastDate)
  // }

  var headerData = []
  var data = []
  if (natDataError || natAllDataError ) {
    // return (
    //   // <ErrorMessage
    //   //   errorCode={errorCode}
    //   //   errorMessage={errorMessage}
    //   //   errorSubject={errorSubject}
    //   // />
    // )
  }
  return (
    <div>
      {natsAll !== undefined ? (
        <DashboardHeader data={headerData} nationalStats={natsAll} />
      ) : (
        <Spinner />
      )}

      <hr style={{ height: '.5px', background: 'grey' }} />
      <Filter />
      <hr style={{ height: '.2px', background: 'grey' }} />
      {nats !== undefined ? (
        <LineGraph data={data} nationalStats={nats} />
      ) : (
        <Spinner />
      )}
      {nats !== undefined ? (
        <FluIndicatorTable data={data} nationalStats={nats} />
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default RespiratorySurveillance
