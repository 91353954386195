import React, { useState, useEffect } from 'react'
import styles from './TopGraphPanel.module.scss'
import BarChart from '../../components/BarChart'

import toolTipFormatter from './toolTipFormatter'
import dataDisplayOptions from './dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import DataTable  from '../../components/DataTable'
import { useGeoFetch, useGeoState } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import Spinner from '../../components/Loader/Spinner';

const userCsvColumns = [
  {
    Header: 'County Name',
    accessor: 'CountyName',
  },
  {
    Header: 'Proc Cd',
    accessor: 'PROC_CD',
  },
  {
    Header: 'Claims',
    accessor: 'CLAIMS',
  },
]

const userColumns = [
  {
    Header: 'County Name',
    accessor: 'CountyName',
    sortType: (a, b) => {
      return a.values.ZIP3.toLowerCase() > b.values.ZIP3.toLowerCase() ? 1 : -1
    },
  },
  {
    Header: 'Proc Cd',
    accessor: 'PROC_CD',
    sortType: (a, b) => {
      return a.values.PROC_CD.toLowerCase() > b.values.PROC_CD.toLowerCase()
        ? 1
        : -1
    },
  },
  {
    Header: 'Claims',
    accessor: 'CLAIMS',
    sortType: (a, b) => {
      return a.values.CLAIMS.toLowerCase() > b.values.CLAIMS.toLowerCase()
        ? 1
        : -1
    },
  },
]

const TopGraphPanel = ({getfilterts}) => {
const [getData, setData] = useState()
const { vulCount } = useGeoState('')
const entity='FluVaccine'
const location='DataCityLevel'
const startDate=''
const endDate=''
const month = getfilterts.month
const fluseason = getfilterts.season
const state= getfilterts.state
const fluFolder = 'DataCityLevel'
const lob =getfilterts.lob
const type=getfilterts.type
const { data, error } = useGeoFetch(startDate, endDate, entity, `${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}&state=${state}`)

var response 

useEffect(() => {
  if (data !== null && data !==undefined)
  {
    if (data.response_data!== null)
    {
      debugger
      response = JSON.parse(data.response_data);
      setData(response)        
    }  
    else{
      setData([])
    }
  }
  else{
    setData([])
  }

}, [data])




if (error) {
  return <ErrorMessage />
}



  return (
    <div className={styles.base}>
      <div className={styles.cityPanel}>
        <span>
          Vaccinations Administered City Level
          <span>
            Patients Count <strong>{vulCount}</strong>
          </span>
        </span>
       {getData?
       <div> <DataTable
          columns={userColumns}
          csvColumns={userCsvColumns}
          data={getData}
          hasPagination
          hasFiltering
          hasSorting
          getfilterts={getfilterts}
          // hasDownloadCsv={true}
        />
        
        <span className={styles.graphSource}>
          Source : <span>Medical and Pharmacy Claims Data</span>
        </span>
        </div>
        :
        <Spinner/>}
      </div>

      <div className={styles.agePanel}>
        <div className={styles.ageDiv}>
          <span>
            Gender and Age Distribution
            <span>
              Patients Count <strong>{vulCount}</strong>
            </span>
          </span>
          <BarChart
            toolTipFormatter={toolTipFormatter}
            entity="FluVaccine"
            location="DetailsDataGenderAge"
            options={stateOptions}
            dataDisplayOptions={dataDisplayOptions}
            chartName="chart2"
            filterId={getfilterts}
            fluFolder='DetailsDataGenderAge'
            month ={getfilterts.month}
            fluseason ={getfilterts.season}
            state={getfilterts.state}
          />
          <span className={styles.graphSource}>
            Source : <span>Medical and Pharmacy Claims Data</span>
          </span>
        </div>
        <div className={styles.genderDiv}>
          {' '}
          <span>
            Type of Vaccine Administered By Gender
            <span>
              Patients Count <strong>{vulCount}</strong>
            </span>
          </span>
          <BarChart
            toolTipFormatter={toolTipFormatter}
            entity="FluVaccine"
            location="DataGenderCode"
            locationDisplay="State"
            startDate={startDate}
            endDate={endDate}
            options={stateOptions}
            dataDisplayOptions={dataDisplayOptions}
            chartName="chart3"
            filterId={getfilterts}
            fluFolder='DataGenderCode'
            month ={getfilterts.month}
            fluseason ={getfilterts.season}
            state={getfilterts.state}
          />
          <span className={styles.graphSource}>
            Source : <span>Medical and Pharmacy Claims Data</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default TopGraphPanel
