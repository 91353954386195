import Highcharts from 'highcharts'
import HC_patternFill from 'highcharts-pattern-fill'
import HC_rounded from 'highcharts-rounded-corners'
HC_rounded(Highcharts)
HC_patternFill(Highcharts)
function getColumnChartOptions(finalData,renderType) {
  return {
    chart: {
      type: 'column',
      height: 300,
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 50,
      floating: true,
    },
    yAxis: {
      title: {
        text: renderType==="dashboard"?'Incidence':'Population Proportion %',
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total + '%'
        },
      },
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
    },
    xAxis: {
      categories: finalData[0].data,
      crosshair: true,
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '700',
          color: '#4B4D4F',
        },
      },
    },
    tooltip: {
      enabled: false, //Added to disable tooltip
      formatter: function () {
        return (
          '<b>' +
          this.x +
          '</b><br/>' +
          this.series.name +
          ': ' +
          this.y +
          '%<br/>'
        )       
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.y + ' %'
          },
        },
        enableMouseTracking: true,
      },
      series: {
        borderRadiusTopLeft: '30%',
        borderRadiusTopRight: '30%',
      },
    },
    defs: {
      patterns: [
        {
          id: 'custom-pattern',
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            stroke: 'white',
            strokeWidth: 1.5,
            fill: '#c71e83',
          },
        },
      ],
    },
    series: [
      {
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: false,
        color: '#15A796',
        enableMouseTracking: false,
      },
      {
        name: finalData[2].name,
        data: finalData[2].data,
        showInLegend: false,
        type: 'column',
        color: 'url(#custom-pattern)',
        enableMouseTracking: false,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {            
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'right',
              labelFormatter: function () {
                return this.name + ': ' + this.y + '%'
              },
            },
          },
        },
      ],
    },
  }
}

export default getColumnChartOptions
