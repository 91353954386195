import moment from 'moment'
import Highcharts from 'highcharts'

function getLineChartOptions(
  height = 300,
  width,
  type,
  hydratedData,
  yaxisName
) {
  //subtract 1 extra day to make dotted line in sink
  //var fordate = moment().add(-moment()._d.getDay() - 1, 'day')._d
  //remove flu index condition because covid and flu are weekly data
  // if (trendName != 'Flu Index') {
  //   fordate = moment()
  // }
  //var vt = hydratedData
  let graph_data = []
  hydratedData.map((val, index) => {
    var arr = []
    arr.push(86400000 + moment(val.data_date, 'YYYY-MM-DD').unix() * 1000)
    arr.push(val.data_value)
    graph_data.push(arr)
  })

  return {
    chart: {
      type: 'line',
      height: height,
    },
    title:
      type === 'sparkline'
        ? { text: '' }
        : {
            text: '',
            align: 'center',
            verticalAlign: 'bottom',
            margin: 50,
            floating: true,
          },
    credits: {
      enabled: false,
    },

    legend:
      type === 'sparkline'
        ? {}
        : {
            enabled: true,
          },
    xAxis:
      type === 'sparkline'
        ? {
            // visible: false,
            gridLineWidth: 1,
            minorGridLineWidth: 1,
            minorTicks: true,
            minorTickInterval: 80,
            labels: {
              enabled: false,
            },
          }
        : {
            type: 'datetime',
            dataSorting: {
              enabled: true,
            },
            labels: {
              formatter: function () {
                // Subtract 1 day from the actual date in this.value to show the x-axis compatible with the data on the trend
                return Highcharts.dateFormat('%b %e', this.value - 86400000)
              },
            },
          },
    yAxis:
      type === 'sparkline'
        ? {
            visible: false,
          }
        : {
            title: {
              text: yaxisName,
            },
          },
    tooltip: {
      formatter: function () {
        return (
          Highcharts.dateFormat('%e-%b-%Y', new Date(this.x)) +
          '<br/>' +
          '<b>' +
          this.y.toFixed(2) +
          '%</b><br/>'
        )
      },
    },

    plotOptions:
      type === 'sparkline'
        ? {
            line: {
              marker: {
                enabled: false,
              },
            },
          }
        : {
            series: {
              label: {
                connectorAllowed: false,
              },
              cursor: 'pointer',
            },
            line: {
              marker: {
                enabled: false,
              },
            },
            area: {
              fillOpacity: 0.5,
              marker: {
                enabled: false,
                symbol: 'circle',
              },
            },
          },
    series: [
      {
        dataLabels:
          type === 'sparkline'
            ? { enabled: false }
            : {
                enabled: true,
                formatter: function () {
                  return Highcharts.dateFormat('%b %e', this.value - 86400000)
                },
              },
        name: 'Patients',
        data: graph_data,
        showInLegend: false,
        color: '#9EAFDA',
      },
    ],
    // responsive: {
    //   rules: [
    //     {
    //       condition: {
    //         maxWidth: 900,
    //       },
    //       chartOptions:
    //         type == 'sparkline'
    //           ? {}
    //           : {
    //               legend: {
    //                 layout: 'horizontal',
    //                 align: 'center',
    //                 verticalAlign: 'bottom',
    //               },
    //             },
    //     },
    //   ],
    // },
  }
}

export default getLineChartOptions
