import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'

import Spinner from '../../components/Loader/Spinner'

import FluAccuracyChart from './FluAccuracyChart'
import seasons from '../../utils/seasons.json'
import {startDate,endDate } from '../../pages/FluMeter/dates'
import styles from './FluDetails.module.scss'
import * as htmlToImage from 'html-to-image'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
export const FluDetails = () => {
  const { user } = useAuthState()

   var logObject={page_name: window.location.pathname , dashboard_type : 'B', indicator_type :'indicator_type', trend_type:'trend_type', state_name:'state_name', metro_area:'metro_area', user_name:user.username, email:user.email};
   

  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const { loc, area } = useParams()

  // state added if we put in a dropdown for later
  // eslint-disable-next-line no-unused-vars
  const [geoData, setGeoData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [seasonsData, setSeasonsData] = useState([])
  const [activeMetros, setActiveMetros] = useState([])

  let queryType = area

  switch (area) {
    case 'metro':
    case 'city':
      queryType = 'msa'
      break

    default:
    // code block
  }

  const [cbsaJsonFile, setCbsaJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setCbsaJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
      });
  }, [1]);
  const [getCountyJsonFile, setCountyJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_county_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setCountyJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_county_20m_wkid102004 ");
      });
  }, [1]);
  const getLatestSeasonsData = (data) => {   
    let filteredData = {}    
    Object.entries(data).forEach(([week, weekData]) => {      
      //if ([seasons[0]].includes(weekData.epi_season)) {
        filteredData[week] = weekData
     // }
    })
    return filteredData
  }

  const fetchData = useCallback(async () => {
    const seasonString = seasons.join(',')
    const res = await fetch(
      `${baseUrl}/flumeter/${queryType}?startDate=${startDate}&endDate=${endDate}&identifier=${loc}`
    )
    const response = await res.json()
    return response
  }, [loc, baseUrl, queryType])

  const fetchActiveMetroData = useCallback(async () => {
    const res = await fetch(`${baseUrl}/flumeter/flu/activecbsa`)
    const response = await res.json()
    return response
  }, [baseUrl])

  useEffect(() => {
    activityLog(logObject);
    const transformGeoData = (data) => {
      const latestSeason = getLatestSeasonsData(data)
      let transformedData = []
      Object.entries(latestSeason).forEach(function (dateObj) {
        const key = dateObj[0]
        const value = dateObj[1][loc]
        transformedData.push({ date: key, ...value })
      })

      setGeoData(transformedData)
    }

    setIsLoading(true)
    Promise.all([fetchData(), fetchActiveMetroData()]).then((response) => {
      transformGeoData(response[0])
      setActiveMetros(response[1])
      setSeasonsData(response[0])
      setIsLoading(false)
      setHasLoaded(true)
    })
  }, [fetchData, fetchActiveMetroData, loc])

  const [searchCriteria, setSearchCriteria] = React.useState([])
  //Added for Save Image to blob
  const disease = 'flumeter'
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const saveImageApiUrl = `${clientConfigApiDomain}/${disease}/saveimage`

  const SaveImage = () => {
    htmlToImage
      .toPng(document.getElementById('root'))
      .then(function (imageData) {
        var urlName = window.location.href
        var ImageName = ''
        if (urlName.indexOf('metro') > -1) {
          var Code = window.location.href.substring(
            window.location.href.lastIndexOf('/') + 1
          )
          ImageName = 'city_accuracy_' + Code + '.png'
        }
        if (urlName.indexOf('state') > -1) {
          var Code = window.location.href.substring(
            window.location.href.lastIndexOf('/') + 1
          )
          ImageName = 'state_accuracy_' + Code + '.png'
        }

        var getImgObject = { name: ImageName, base64Content: imageData }
        fetch(saveImageApiUrl, {
          method: 'post',
          body: JSON.stringify(getImgObject),
        })
          .then((response) => {
            if (!response.ok) throw Error('Upload failed')
            response.json().then((responseBody) => {})
          })
          .catch(() => {
            console.log('Catch Error')
          })
      })
  }

  return (
    <div className={styles.base}>
      {isLoading ? (
        <Spinner />
      ) : hasLoaded && cbsaJsonFile && getCountyJsonFile? (
        <>
          <div className={styles.body}>
            {/* <h4
              style={{
                paddingRight: '10px',
              }}
            >
              Accuracy Report Comparison for{' '}
              <span className={styles.capitalize}>{displayType}</span>:{' '}
              {displayName}
            </h4> */}
            <FluAccuracyChart cbsaJson={cbsaJsonFile} countyJson={getCountyJsonFile} data={geoData} identifier={loc} type={area} />
            {/* <FluSeasonsChart data={seasonsData} identifier={loc} /> */}
          </div>
        </>
      ) : null}
      <button
        id="btnSaveImage"
        style={{ border: 'none', color: 'white', backgroundColor: 'white' }}
        onClick={() => {
          SaveImage()
        }}
      >
        .
      </button>
    </div>
  )
}

export default FluDetails
