const regionDisplayOptions = [
  { value: '10420', locationtype: 'MSA', label: 'Akron- OH' },
  { value: '10500', locationtype: 'MSA', label: 'Albany- GA' },
  { value: '10580', locationtype: 'MSA', label: 'Albany-Schenectady-Troy- NY' },
  { value: '10740', locationtype: 'MSA', label: 'Albuquerque- NM' },
  { value: '10780', locationtype: 'MSA', label: 'Alexandria- LA' },
  {
    value: '10900',
    locationtype: 'MSA',
    label: 'Allentown-Bethlehem-Easton- PA-NJ',
  },
  { value: '11020', locationtype: 'MSA', label: 'Altoona- PA' },
  { value: '11260', locationtype: 'MSA', label: 'Anchorage- AK' },
  { value: '11460', locationtype: 'MSA', label: 'Ann Arbor- MI' },
  { value: '11540', locationtype: 'MSA', label: 'Appleton- WI' },
  { value: '11700', locationtype: 'MSA', label: 'Asheville- NC' },
  {
    value: '12060',
    locationtype: 'MSA',
    label: 'Atlanta-Sandy Springs-Alpharetta- GA',
  },
  { value: '12100', locationtype: 'MSA', label: 'Atlantic City-Hammonton- NJ' },
  {
    value: '12420',
    locationtype: 'MSA',
    label: 'Austin-Round Rock-Georgetown- TX',
  },
  { value: '12540', locationtype: 'MSA', label: 'Bakersfield- CA' },
  {
    value: '12580',
    locationtype: 'MSA',
    label: 'Baltimore-Columbia-Towson- MD',
  },
  { value: '12620', locationtype: 'MSA', label: 'Bangor- ME' },
  { value: '12940', locationtype: 'MSA', label: 'Baton Rouge- LA' },
  { value: '12980', locationtype: 'MSA', label: 'Battle Creek- MI' },
  { value: '13140', locationtype: 'MSA', label: 'Beaumont-Port Arthur- TX' },
  { value: '13220', locationtype: 'MSA', label: 'Beckley- WV' },
  { value: '13380', locationtype: 'MSA', label: 'Bellingham- WA' },
  { value: '13460', locationtype: 'MSA', label: 'Bend- OR' },
  { value: '13740', locationtype: 'MSA', label: 'Billings- MT' },
  { value: '13820', locationtype: 'MSA', label: 'Birmingham-Hoover- AL' },
  {
    value: '13980',
    locationtype: 'MSA',
    label: 'Blacksburg-Christiansburg- VA',
  },
  { value: '14010', locationtype: 'MSA', label: 'Bloomington- IL' },
  { value: '14020', locationtype: 'MSA', label: 'Bloomington- IN' },
  { value: '14260', locationtype: 'MSA', label: 'Boise City- ID' },
  {
    value: '14460',
    locationtype: 'MSA',
    label: 'Boston-Cambridge-Newton- MA-NH',
  },
  { value: '14500', locationtype: 'MSA', label: 'Boulder- CO' },
  { value: '14540', locationtype: 'MSA', label: 'Bowling Green- KY' },
  {
    value: '14740',
    locationtype: 'MSA',
    label: 'Bremerton-Silverdale-Port Orchard- WA',
  },
  {
    value: '14860',
    locationtype: 'MSA',
    label: 'Bridgeport-Stamford-Norwalk- CT',
  },
  { value: '15180', locationtype: 'MSA', label: 'Brownsville-Harlingen- TX' },
  { value: '15260', locationtype: 'MSA', label: 'Brunswick- GA' },
  { value: '15380', locationtype: 'MSA', label: 'Buffalo-Cheektowaga- NY' },
  { value: '15500', locationtype: 'MSA', label: 'Burlington- NC' },
  {
    value: '15540',
    locationtype: 'MSA',
    label: 'Burlington-South Burlington- VT',
  },
  {
    value: '15680',
    locationtype: 'MSA',
    label: 'California-Lexington Park- MD',
  },
  { value: '15940', locationtype: 'MSA', label: 'Canton-Massillon- OH' },
  { value: '15980', locationtype: 'MSA', label: 'Cape Coral-Fort Myers- FL' },
  { value: '16020', locationtype: 'MSA', label: 'Cape Girardeau- MO-IL' },
  { value: '16060', locationtype: 'MSA', label: 'Carbondale-Marion- IL' },
  { value: '16220', locationtype: 'MSA', label: 'Casper- WY' },
  { value: '16300', locationtype: 'MSA', label: 'Cedar Rapids- IA' },
  { value: '16540', locationtype: 'MSA', label: 'Chambersburg-Waynesboro- PA' },
  { value: '16620', locationtype: 'MSA', label: 'Charleston- WV' },
  {
    value: '16700',
    locationtype: 'MSA',
    label: 'Charleston-North Charleston- SC',
  },
  {
    value: '16740',
    locationtype: 'MSA',
    label: 'Charlotte-Concord-Gastonia- NC-SC',
  },
  { value: '16820', locationtype: 'MSA', label: 'Charlottesville- VA' },
  { value: '16860', locationtype: 'MSA', label: 'Chattanooga- TN-GA' },
  { value: '16940', locationtype: 'MSA', label: 'Cheyenne- WY' },
  {
    value: '16980',
    locationtype: 'MSA',
    label: 'Chicago-Naperville-Elgin- IL-IN-WI',
  },
  { value: '17020', locationtype: 'MSA', label: 'Chico- CA' },
  { value: '17140', locationtype: 'MSA', label: 'Cincinnati- OH-KY-IN' },
  { value: '17420', locationtype: 'MSA', label: 'Cleveland- TN' },
  { value: '17460', locationtype: 'MSA', label: 'Cleveland-Elyria- OH' },
  { value: '17820', locationtype: 'MSA', label: 'Colorado Springs- CO' },
  { value: '17860', locationtype: 'MSA', label: 'Columbia- MO' },
  { value: '17900', locationtype: 'MSA', label: 'Columbia- SC' },
  { value: '17980', locationtype: 'MSA', label: 'Columbus- GA-AL' },
  { value: '18020', locationtype: 'MSA', label: 'Columbus- IN' },
  { value: '18140', locationtype: 'MSA', label: 'Columbus- OH' },
  { value: '18180', locationtype: 'MSA', label: 'Concord- NH' },
  { value: '18580', locationtype: 'MSA', label: 'Corpus Christi- TX' },
  { value: '18700', locationtype: 'MSA', label: 'Corvallis- OR' },
  {
    value: '18880',
    locationtype: 'MSA',
    label: 'Crestview-Fort Walton Beach-Destin- FL',
  },
  { value: '19060', locationtype: 'MSA', label: 'Cumberland- MD-WV' },
  {
    value: '19100',
    locationtype: 'MSA',
    label: 'Dallas-Fort Worth-Arlington- TX',
  },
  { value: '19140', locationtype: 'MSA', label: 'Dalton- GA' },
  { value: '19300', locationtype: 'MSA', label: 'Daphne-Fairhope-Foley- AL' },
  {
    value: '19340',
    locationtype: 'MSA',
    label: 'Davenport-Moline-Rock Island- IA-IL',
  },
  { value: '19430', locationtype: 'MSA', label: 'Dayton-Kettering- OH' },
  { value: '19500', locationtype: 'MSA', label: 'Decatur- IL' },
  {
    value: '19660',
    locationtype: 'MSA',
    label: 'Deltona-Daytona Beach-Ormond Beach- FL',
  },
  { value: '19740', locationtype: 'MSA', label: 'Denver-Aurora-Lakewood- CO' },
  {
    value: '19780',
    locationtype: 'MSA',
    label: 'Des Moines-West Des Moines- IA',
  },
  { value: '19820', locationtype: 'MSA', label: 'Detroit-Warren-Dearborn- MI' },
  { value: '20020', locationtype: 'MSA', label: 'Dothan- AL' },
  { value: '20100', locationtype: 'MSA', label: 'Dover- DE' },
  { value: '20260', locationtype: 'MSA', label: 'Duluth- MN-WI' },
  { value: '20500', locationtype: 'MSA', label: 'Durham-Chapel Hill- NC' },
  { value: '20700', locationtype: 'MSA', label: 'East Stroudsburg- PA' },
  { value: '20740', locationtype: 'MSA', label: 'Eau Claire- WI' },
  { value: '20940', locationtype: 'MSA', label: 'El Centro- CA' },
  { value: '21340', locationtype: 'MSA', label: 'El Paso- TX' },
  { value: '21140', locationtype: 'MSA', label: 'Elkhart-Goshen- IN' },
  { value: '21500', locationtype: 'MSA', label: 'Erie- PA' },
  { value: '21660', locationtype: 'MSA', label: 'Eugene-Springfield- OR' },
  { value: '21780', locationtype: 'MSA', label: 'Evansville- IN-KY' },
  { value: '22180', locationtype: 'MSA', label: 'Fayetteville- NC' },
  {
    value: '22220',
    locationtype: 'MSA',
    label: 'Fayetteville-Springdale-Rogers- AR',
  },
  { value: '22380', locationtype: 'MSA', label: 'Flagstaff- AZ' },
  { value: '22420', locationtype: 'MSA', label: 'Flint- MI' },
  { value: '22500', locationtype: 'MSA', label: 'Florence- SC' },
  { value: '22520', locationtype: 'MSA', label: 'Florence-Muscle Shoals- AL' },
  { value: '22540', locationtype: 'MSA', label: 'Fond du Lac- WI' },
  { value: '22660', locationtype: 'MSA', label: 'Fort Collins- CO' },
  { value: '22900', locationtype: 'MSA', label: 'Fort Smith- AR-OK' },
  { value: '23060', locationtype: 'MSA', label: 'Fort Wayne- IN' },
  { value: '23420', locationtype: 'MSA', label: 'Fresno- CA' },
  { value: '23460', locationtype: 'MSA', label: 'Gadsden- AL' },
  { value: '23540', locationtype: 'MSA', label: 'Gainesville- FL' },
  { value: '23580', locationtype: 'MSA', label: 'Gainesville- GA' },
  { value: '23900', locationtype: 'MSA', label: 'Gettysburg- PA' },
  { value: '24020', locationtype: 'MSA', label: 'Glens Falls- NY' },
  { value: '24220', locationtype: 'MSA', label: 'Grand Forks- ND-MN' },
  { value: '24260', locationtype: 'MSA', label: 'Grand Island- NE' },
  { value: '24300', locationtype: 'MSA', label: 'Grand Junction- CO' },
  { value: '24340', locationtype: 'MSA', label: 'Grand Rapids-Kentwood- MI' },
  { value: '24420', locationtype: 'MSA', label: 'Grants Pass- OR' },
  { value: '24540', locationtype: 'MSA', label: 'Greeley- CO' },
  { value: '24580', locationtype: 'MSA', label: 'Green Bay- WI' },
  { value: '24660', locationtype: 'MSA', label: 'Greensboro-High Point- NC' },
  { value: '24780', locationtype: 'MSA', label: 'Greenville- NC' },
  { value: '24860', locationtype: 'MSA', label: 'Greenville-Anderson- SC' },
  { value: '25060', locationtype: 'MSA', label: 'Gulfport-Biloxi- MS' },
  {
    value: '25180',
    locationtype: 'MSA',
    label: 'Hagerstown-Martinsburg- MD-WV',
  },
  { value: '25220', locationtype: 'MSA', label: 'Hammond- LA' },
  { value: '25260', locationtype: 'MSA', label: 'Hanford-Corcoran- CA' },
  { value: '25420', locationtype: 'MSA', label: 'Harrisburg-Carlisle- PA' },
  { value: '25500', locationtype: 'MSA', label: 'Harrisonburg- VA' },
  {
    value: '25540',
    locationtype: 'MSA',
    label: 'Hartford-East Hartford-Middletown- CT',
  },
  {
    value: '25860',
    locationtype: 'MSA',
    label: 'Hickory-Lenoir-Morganton- NC',
  },
  {
    value: '25940',
    locationtype: 'MSA',
    label: 'Hilton Head Island-Bluffton- SC',
  },
  { value: '26140', locationtype: 'MSA', label: 'Homosassa Springs- FL' },
  { value: '26300', locationtype: 'MSA', label: 'Hot Springs- AR' },
  { value: '26380', locationtype: 'MSA', label: 'Houma-Thibodaux- LA' },
  {
    value: '26420',
    locationtype: 'MSA',
    label: 'Houston-The Woodlands-Sugar Land- TX',
  },
  {
    value: '26580',
    locationtype: 'MSA',
    label: 'Huntington-Ashland- WV-KY-OH',
  },
  { value: '26620', locationtype: 'MSA', label: 'Huntsville- AL' },
  { value: '26820', locationtype: 'MSA', label: 'Idaho Falls- ID' },
  {
    value: '26900',
    locationtype: 'MSA',
    label: 'Indianapolis-Carmel-Anderson- IN',
  },
  { value: '26980', locationtype: 'MSA', label: 'Iowa City- IA' },
  { value: '27100', locationtype: 'MSA', label: 'Jackson- MI' },
  { value: '27140', locationtype: 'MSA', label: 'Jackson- MS' },
  { value: '27260', locationtype: 'MSA', label: 'Jacksonville- FL' },
  { value: '27500', locationtype: 'MSA', label: 'Janesville-Beloit- WI' },
  { value: '27740', locationtype: 'MSA', label: 'Johnson City- TN' },
  { value: '27780', locationtype: 'MSA', label: 'Johnstown- PA' },
  { value: '27860', locationtype: 'MSA', label: 'Jonesboro- AR' },
  { value: '27900', locationtype: 'MSA', label: 'Joplin- MO' },
  { value: '27980', locationtype: 'MSA', label: 'Kahului-Wailuku-Lahaina- HI' },
  { value: '28020', locationtype: 'MSA', label: 'Kalamazoo-Portage- MI' },
  { value: '28140', locationtype: 'MSA', label: 'Kansas City- MO-KS' },
  { value: '28660', locationtype: 'MSA', label: 'Killeen-Temple- TX' },
  { value: '28700', locationtype: 'MSA', label: 'Kingsport-Bristol- TN-VA' },
  { value: '28740', locationtype: 'MSA', label: 'Kingston- NY' },
  { value: '28940', locationtype: 'MSA', label: 'Knoxville- TN' },
  { value: '29020', locationtype: 'MSA', label: 'Kokomo- IN' },
  { value: '29100', locationtype: 'MSA', label: 'La Crosse-Onalaska- WI-MN' },
  { value: '29180', locationtype: 'MSA', label: 'Lafayette- LA' },
  {
    value: '29200',
    locationtype: 'MSA',
    label: 'Lafayette-West Lafayette- IN',
  },
  { value: '29340', locationtype: 'MSA', label: 'Lake Charles- LA' },
  {
    value: '29420',
    locationtype: 'MSA',
    label: 'Lake Havasu City-Kingman- AZ',
  },
  { value: '29460', locationtype: 'MSA', label: 'Lakeland-Winter Haven- FL' },
  { value: '29540', locationtype: 'MSA', label: 'Lancaster- PA' },
  { value: '29620', locationtype: 'MSA', label: 'Lansing-East Lansing- MI' },
  { value: '29700', locationtype: 'MSA', label: 'Laredo- TX' },
  { value: '29740', locationtype: 'MSA', label: 'Las Cruces- NM' },
  {
    value: '29820',
    locationtype: 'MSA',
    label: 'Las Vegas-Henderson-Paradise- NV',
  },
  { value: '29940', locationtype: 'MSA', label: 'Lawrence- KS' },
  { value: '30020', locationtype: 'MSA', label: 'Lawton- OK' },
  { value: '30140', locationtype: 'MSA', label: 'Lebanon- PA' },
  { value: '30460', locationtype: 'MSA', label: 'Lexington-Fayette- KY' },
  { value: '30620', locationtype: 'MSA', label: 'Lima- OH' },
  { value: '30700', locationtype: 'MSA', label: 'Lincoln- NE' },
  {
    value: '30780',
    locationtype: 'MSA',
    label: 'Little Rock-North Little Rock-Conway- AR',
  },
  { value: '30860', locationtype: 'MSA', label: 'Logan- UT-ID' },
  { value: '30980', locationtype: 'MSA', label: 'Longview- TX' },
  { value: '31020', locationtype: 'MSA', label: 'Longview- WA' },
  {
    value: '31080',
    locationtype: 'MSA',
    label: 'Los Angeles-Long Beach-Anaheim- CA',
  },
  {
    value: '31140',
    locationtype: 'MSA',
    label: 'Louisville/Jefferson County- KY-IN',
  },
  { value: '31180', locationtype: 'MSA', label: 'Lubbock- TX' },
  { value: '31340', locationtype: 'MSA', label: 'Lynchburg- VA' },
  { value: '31420', locationtype: 'MSA', label: 'Macon-Bibb County- GA' },
  { value: '31460', locationtype: 'MSA', label: 'Madera- CA' },
  { value: '31540', locationtype: 'MSA', label: 'Madison- WI' },
  { value: '31700', locationtype: 'MSA', label: 'Manchester-Nashua- NH' },
  { value: '31860', locationtype: 'MSA', label: 'Mankato- MN' },
  { value: '31900', locationtype: 'MSA', label: 'Mansfield- OH' },
  {
    value: '32580',
    locationtype: 'MSA',
    label: 'McAllen-Edinburg-Mission- TX',
  },
  { value: '32780', locationtype: 'MSA', label: 'Medford- OR' },
  { value: '32820', locationtype: 'MSA', label: 'Memphis- TN-MS-AR' },
  { value: '32900', locationtype: 'MSA', label: 'Merced- CA' },
  {
    value: '33100',
    locationtype: 'MSA',
    label: 'Miami-Fort Lauderdale-Pompano Beach- FL',
  },
  { value: '33140', locationtype: 'MSA', label: 'Michigan City-La Porte- IN' },
  { value: '33340', locationtype: 'MSA', label: 'Milwaukee-Waukesha- WI' },
  {
    value: '33460',
    locationtype: 'MSA',
    label: 'Minneapolis-St. Paul-Bloomington- MN-WI',
  },
  { value: '33660', locationtype: 'MSA', label: 'Mobile- AL' },
  { value: '33700', locationtype: 'MSA', label: 'Modesto- CA' },
  { value: '33740', locationtype: 'MSA', label: 'Monroe- LA' },
  { value: '33780', locationtype: 'MSA', label: 'Monroe- MI' },
  { value: '33860', locationtype: 'MSA', label: 'Montgomery- AL' },
  { value: '34060', locationtype: 'MSA', label: 'Morgantown- WV' },
  { value: '34100', locationtype: 'MSA', label: 'Morristown- TN' },
  { value: '34580', locationtype: 'MSA', label: 'Mount Vernon-Anacortes- WA' },
  { value: '34620', locationtype: 'MSA', label: 'Muncie- IN' },
  { value: '34740', locationtype: 'MSA', label: 'Muskegon- MI' },
  {
    value: '34820',
    locationtype: 'MSA',
    label: 'Myrtle Beach-Conway-North Myrtle Beach- SC-NC',
  },
  { value: '34900', locationtype: 'MSA', label: 'Napa- CA' },
  { value: '34940', locationtype: 'MSA', label: 'Naples-Marco Island- FL' },
  {
    value: '34980',
    locationtype: 'MSA',
    label: 'Nashville-Davidson--Murfreesboro--Franklin- TN',
  },
  { value: '35100', locationtype: 'MSA', label: 'New Bern- NC' },
  { value: '35300', locationtype: 'MSA', label: 'New Haven-Milford- CT' },
  { value: '35380', locationtype: 'MSA', label: 'New Orleans-Metairie- LA' },
  {
    value: '35620',
    locationtype: 'MSA',
    label: 'New York-Newark-Jersey City- NY-NJ-PA',
  },
  { value: '35660', locationtype: 'MSA', label: 'Niles- MI' },
  {
    value: '35840',
    locationtype: 'MSA',
    label: 'North Port-Sarasota-Bradenton- FL',
  },
  { value: '35980', locationtype: 'MSA', label: 'Norwich-New London- CT' },
  { value: '36100', locationtype: 'MSA', label: 'Ocala- FL' },
  { value: '36140', locationtype: 'MSA', label: 'Ocean City- NJ' },
  { value: '36260', locationtype: 'MSA', label: 'Ogden-Clearfield- UT' },
  { value: '36420', locationtype: 'MSA', label: 'Oklahoma City- OK' },
  { value: '36500', locationtype: 'MSA', label: 'Olympia-Lacey-Tumwater- WA' },
  { value: '36540', locationtype: 'MSA', label: 'Omaha-Council Bluffs- NE-IA' },
  {
    value: '36740',
    locationtype: 'MSA',
    label: 'Orlando-Kissimmee-Sanford- FL',
  },
  { value: '36780', locationtype: 'MSA', label: 'Oshkosh-Neenah- WI' },
  { value: '36980', locationtype: 'MSA', label: 'Owensboro- KY' },
  {
    value: '37100',
    locationtype: 'MSA',
    label: 'Oxnard-Thousand Oaks-Ventura- CA',
  },
  {
    value: '37340',
    locationtype: 'MSA',
    label: 'Palm Bay-Melbourne-Titusville- FL',
  },
  { value: '37460', locationtype: 'MSA', label: 'Panama City- FL' },
  { value: '37620', locationtype: 'MSA', label: 'Parkersburg-Vienna- WV' },
  {
    value: '37860',
    locationtype: 'MSA',
    label: 'Pensacola-Ferry Pass-Brent- FL',
  },
  { value: '37900', locationtype: 'MSA', label: 'Peoria- IL' },
  {
    value: '37980',
    locationtype: 'MSA',
    label: 'Philadelphia-Camden-Wilmington- PA-NJ-DE-MD',
  },
  { value: '38060', locationtype: 'MSA', label: 'Phoenix-Mesa-Chandler- AZ' },
  { value: '38220', locationtype: 'MSA', label: 'Pine Bluff- AR' },
  { value: '38300', locationtype: 'MSA', label: 'Pittsburgh- PA' },
  { value: '38340', locationtype: 'MSA', label: 'Pittsfield- MA' },
  { value: '38940', locationtype: 'MSA', label: 'Port St. Lucie- FL' },
  { value: '38860', locationtype: 'MSA', label: 'Portland-South Portland- ME' },
  {
    value: '38900',
    locationtype: 'MSA',
    label: 'Portland-Vancouver-Hillsboro- OR-WA',
  },
  {
    value: '39100',
    locationtype: 'MSA',
    label: 'Poughkeepsie-Newburgh-Middletown- NY',
  },
  {
    value: '39150',
    locationtype: 'MSA',
    label: 'Prescott Valley-Prescott- AZ',
  },
  { value: '39300', locationtype: 'MSA', label: 'Providence-Warwick- RI-MA' },
  { value: '39340', locationtype: 'MSA', label: 'Provo-Orem- UT' },
  { value: '39380', locationtype: 'MSA', label: 'Pueblo- CO' },
  { value: '39460', locationtype: 'MSA', label: 'Punta Gorda- FL' },
  { value: '39540', locationtype: 'MSA', label: 'Racine- WI' },
  { value: '39580', locationtype: 'MSA', label: 'Raleigh-Cary- NC' },
  { value: '39740', locationtype: 'MSA', label: 'Reading- PA' },
  { value: '39820', locationtype: 'MSA', label: 'Redding- CA' },
  { value: '39900', locationtype: 'MSA', label: 'Reno- NV' },
  { value: '40060', locationtype: 'MSA', label: 'Richmond- VA' },
  {
    value: '40140',
    locationtype: 'MSA',
    label: 'Riverside-San Bernardino-Ontario- CA',
  },
  { value: '40220', locationtype: 'MSA', label: 'Roanoke- VA' },
  { value: '40340', locationtype: 'MSA', label: 'Rochester- MN' },
  { value: '40380', locationtype: 'MSA', label: 'Rochester- NY' },
  { value: '40420', locationtype: 'MSA', label: 'Rockford- IL' },
  { value: '40580', locationtype: 'MSA', label: 'Rocky Mount- NC' },
  { value: '40660', locationtype: 'MSA', label: 'Rome- GA' },
  {
    value: '40900',
    locationtype: 'MSA',
    label: 'Sacramento-Roseville-Folsom- CA',
  },
  { value: '40980', locationtype: 'MSA', label: 'Saginaw- MI' },
  { value: '41420', locationtype: 'MSA', label: 'Salem- OR' },
  { value: '41500', locationtype: 'MSA', label: 'Salinas- CA' },
  { value: '41540', locationtype: 'MSA', label: 'Salisbury- MD-DE' },
  { value: '41620', locationtype: 'MSA', label: 'Salt Lake City- UT' },
  { value: '41660', locationtype: 'MSA', label: 'San Angelo- TX' },
  {
    value: '41700',
    locationtype: 'MSA',
    label: 'San Antonio-New Braunfels- TX',
  },
  {
    value: '41740',
    locationtype: 'MSA',
    label: 'San Diego-Chula Vista-Carlsbad- CA',
  },
  {
    value: '41860',
    locationtype: 'MSA',
    label: 'San Francisco-Oakland-Berkeley- CA',
  },
  {
    value: '41940',
    locationtype: 'MSA',
    label: 'San Jose-Sunnyvale-Santa Clara- CA',
  },
  { value: '41980', locationtype: 'MSA', label: 'San Juan-Bayamón-Caguas- PR' },
  {
    value: '42020',
    locationtype: 'MSA',
    label: 'San Luis Obispo-Paso Robles- CA',
  },
  { value: '42100', locationtype: 'MSA', label: 'Santa Cruz-Watsonville- CA' },
  {
    value: '42200',
    locationtype: 'MSA',
    label: 'Santa Maria-Santa Barbara- CA',
  },
  { value: '42220', locationtype: 'MSA', label: 'Santa Rosa-Petaluma- CA' },
  { value: '42340', locationtype: 'MSA', label: 'Savannah- GA' },
  { value: '42540', locationtype: 'MSA', label: 'Scranton--Wilkes-Barre- PA' },
  { value: '42660', locationtype: 'MSA', label: 'Seattle-Tacoma-Bellevue- WA' },
  { value: '42680', locationtype: 'MSA', label: 'Sebastian-Vero Beach- FL' },
  { value: '43300', locationtype: 'MSA', label: 'Sherman-Denison- TX' },
  { value: '43340', locationtype: 'MSA', label: 'Shreveport-Bossier City- LA' },
  { value: '43420', locationtype: 'MSA', label: 'Sierra Vista-Douglas- AZ' },
  { value: '43580', locationtype: 'MSA', label: 'Sioux City- IA-NE-SD' },
  { value: '43780', locationtype: 'MSA', label: 'South Bend-Mishawaka- IN-MI' },
  { value: '43900', locationtype: 'MSA', label: 'Spartanburg- SC' },
  { value: '44060', locationtype: 'MSA', label: 'Spokane-Spokane Valley- WA' },
  { value: '44140', locationtype: 'MSA', label: 'Springfield- MA' },
  { value: '44180', locationtype: 'MSA', label: 'Springfield- MO' },
  { value: '41060', locationtype: 'MSA', label: 'St. Cloud- MN' },
  { value: '41140', locationtype: 'MSA', label: 'St. Joseph- MO-KS' },
  { value: '41180', locationtype: 'MSA', label: 'St. Louis- MO-IL' },
  { value: '44300', locationtype: 'MSA', label: 'State College- PA' },
  { value: '44420', locationtype: 'MSA', label: 'Staunton- VA' },
  { value: '44700', locationtype: 'MSA', label: 'Stockton- CA' },
  { value: '45060', locationtype: 'MSA', label: 'Syracuse- NY' },
  { value: '45220', locationtype: 'MSA', label: 'Tallahassee- FL' },
  {
    value: '45300',
    locationtype: 'MSA',
    label: 'Tampa-St. Petersburg-Clearwater- FL',
  },
  { value: '45460', locationtype: 'MSA', label: 'Terre Haute- IN' },
  { value: '45500', locationtype: 'MSA', label: 'Texarkana- TX-AR' },
  { value: '45540', locationtype: 'MSA', label: 'The Villages- FL' },
  { value: '45780', locationtype: 'MSA', label: 'Toledo- OH' },
  { value: '45820', locationtype: 'MSA', label: 'Topeka- KS' },
  { value: '45940', locationtype: 'MSA', label: 'Trenton-Princeton- NJ' },
  { value: '46060', locationtype: 'MSA', label: 'Tucson- AZ' },
  { value: '46140', locationtype: 'MSA', label: 'Tulsa- OK' },
  { value: '46220', locationtype: 'MSA', label: 'Tuscaloosa- AL' },
  { value: '46340', locationtype: 'MSA', label: 'Tyler- TX' },
  { value: '46520', locationtype: 'MSA', label: 'Urban Honolulu- HI' },
  { value: '46700', locationtype: 'MSA', label: 'Vallejo- CA' },
  { value: '47020', locationtype: 'MSA', label: 'Victoria- TX' },
  { value: '47220', locationtype: 'MSA', label: 'Vineland-Bridgeton- NJ' },
  {
    value: '47260',
    locationtype: 'MSA',
    label: 'Virginia Beach-Norfolk-Newport News- VA-NC',
  },
  { value: '47300', locationtype: 'MSA', label: 'Visalia- CA' },
  { value: '47380', locationtype: 'MSA', label: 'Waco- TX' },
  { value: '47460', locationtype: 'MSA', label: 'Walla Walla- WA' },
  { value: '47580', locationtype: 'MSA', label: 'Warner Robins- GA' },
  {
    value: '47900',
    locationtype: 'MSA',
    label: 'Washington-Arlington-Alexandria- DC-VA-MD-WV',
  },
  { value: '47940', locationtype: 'MSA', label: 'Waterloo-Cedar Falls- IA' },
  { value: '48060', locationtype: 'MSA', label: 'Watertown-Fort Drum- NY' },
  { value: '48140', locationtype: 'MSA', label: 'Wausau-Weston- WI' },
  { value: '48260', locationtype: 'MSA', label: 'Weirton-Steubenville- WV-OH' },
  { value: '48540', locationtype: 'MSA', label: 'Wheeling- WV-OH' },
  { value: '48620', locationtype: 'MSA', label: 'Wichita- KS' },
  { value: '48700', locationtype: 'MSA', label: 'Williamsport- PA' },
  { value: '48900', locationtype: 'MSA', label: 'Wilmington- NC' },
  { value: '49020', locationtype: 'MSA', label: 'Winchester- VA-WV' },
  { value: '49180', locationtype: 'MSA', label: 'Winston-Salem- NC' },
  { value: '49340', locationtype: 'MSA', label: 'Worcester- MA-CT' },
  { value: '49420', locationtype: 'MSA', label: 'Yakima- WA' },
  { value: '49620', locationtype: 'MSA', label: 'York-Hanover- PA' },
  {
    value: '49660',
    locationtype: 'MSA',
    label: 'Youngstown-Warren-Boardman- OH-PA',
  },
  { value: '49740', locationtype: 'MSA', label: 'Yuma- AZ' },
  { value: 'NAT', locationtype: 'NAT', label: 'US' },
  { value: 'AL', locationtype: 'STATE', label: 'Alabama' },
  { value: 'AK', locationtype: 'STATE', label: 'Alaska' },
  { value: 'AZ', locationtype: 'STATE', label: 'Arizona' },
  { value: 'AR', locationtype: 'STATE', label: 'Arkansas' },
  { value: 'CA', locationtype: 'STATE', label: 'California' },
  { value: 'CO', locationtype: 'STATE', label: 'Colorado' },
  { value: 'CT', locationtype: 'STATE', label: 'Connecticut' },
  { value: 'DE', locationtype: 'STATE', label: 'Delaware' },
  { value: 'DC', locationtype: 'STATE', label: 'District of Columbia' },
  { value: 'FL', locationtype: 'STATE', label: 'Florida' },
  { value: 'GA', locationtype: 'STATE', label: 'Georgia' },
  { value: 'HI', locationtype: 'STATE', label: 'Hawaii' },
  { value: 'ID', locationtype: 'STATE', label: 'Idaho' },
  { value: 'IL', locationtype: 'STATE', label: 'Illinois' },
  { value: 'IN', locationtype: 'STATE', label: 'Indiana' },
  { value: 'IA', locationtype: 'STATE', label: 'Iowa' },
  { value: 'KS', locationtype: 'STATE', label: 'Kansas' },
  { value: 'KY', locationtype: 'STATE', label: 'Kentucky' },
  { value: 'LA', locationtype: 'STATE', label: 'Louisiana' },
  { value: 'ME', locationtype: 'STATE', label: 'Maine' },
  { value: 'MD', locationtype: 'STATE', label: 'Maryland' },
  { value: 'MA', locationtype: 'STATE', label: 'Massachusetts' },
  { value: 'MI', locationtype: 'STATE', label: 'Michigan' },
  { value: 'MN', locationtype: 'STATE', label: 'Minnesota' },
  { value: 'MS', locationtype: 'STATE', label: 'Mississippi' },
  { value: 'MO', locationtype: 'STATE', label: 'Missouri' },
  { value: 'MT', locationtype: 'STATE', label: 'Montana' },
  { value: 'NE', locationtype: 'STATE', label: 'Nebraska' },
  { value: 'NV', locationtype: 'STATE', label: 'Nevada' },
  { value: 'NH', locationtype: 'STATE', label: 'New Hampshire' },
  { value: 'NJ', locationtype: 'STATE', label: 'New Jersey' },
  { value: 'NM', locationtype: 'STATE', label: 'New Mexico' },
  { value: 'NY', locationtype: 'STATE', label: 'New York' },
  { value: 'NC', locationtype: 'STATE', label: 'North Carolina' },
  { value: 'ND', locationtype: 'STATE', label: 'North Dakota' },
  { value: 'OH', locationtype: 'STATE', label: 'Ohio' },
  { value: 'OK', locationtype: 'STATE', label: 'Oklahoma' },
  { value: 'OR', locationtype: 'STATE', label: 'Oregon' },
  { value: 'PA', locationtype: 'STATE', label: 'Pennsylvania' },
  { value: 'RI', locationtype: 'STATE', label: 'Rhode Island' },
  { value: 'SC', locationtype: 'STATE', label: 'South Carolina' },
  { value: 'SD', locationtype: 'STATE', label: 'South Dakota' },
  { value: 'TN', locationtype: 'STATE', label: 'Tennessee' },
  { value: 'TX', locationtype: 'STATE', label: 'Texas' },
  { value: 'UT', locationtype: 'STATE', label: 'Utah' },
  { value: 'VT', locationtype: 'STATE', label: 'Vermont' },
  { value: 'VA', locationtype: 'STATE', label: 'Virginia' },
  { value: 'WA', locationtype: 'STATE', label: 'Washington' },
  { value: 'WV', locationtype: 'STATE', label: 'West Virginia' },
  { value: 'WI', locationtype: 'STATE', label: 'Wisconsin' },
  { value: 'WY', locationtype: 'STATE', label: 'Wyoming' },
]

export default regionDisplayOptions
