import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { useGeoFetch } from '../../../components/GeoProvider'
import GeoProvider from '../../../components/GeoProvider'
import Map from '../../../components/Map'
import EmptyMap from '../../../components/Map/emptyMap'
//import usCountiesAll from '../../../components/Map/us_hrr_values1.json'

import colorAxisCasesWeekly from '../../Covid-19/Covid19Map/colorAxisCasesWeekly'
import colorAxisCasesWeeklyHrr from '../../Covid-19/Covid19Map/colorAxisCasesWeeklyHrr'

import HRRDefaults from './HRRDefaults'
import styles from './HrrMap.module.scss'

const HrrMap = () => {
  var path='static'
  if (process.env.REACT_APP_UI_HOME.includes("local"))
  {
    var path=''
  }
  const appurl = `${process.env.REACT_APP_UI_HOME}/${path}/hrrDashboardMap.png`
  const history = useHistory()
  const today = dayjs().day(-7).format('YYYY-MM-DD')
  const weekEndData = dayjs().day(-1).format('YYYY-MM-DD')

  const hrrenddate = dayjs().day(-4).format('YYYY-MM-DD')
  const [setErrorCode] = useState()
  const [setErrorMessage] = useState()
  const [setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const mapOptions = {
    value: 'hospital_admissions',
    label: 'Weekly Hospitalizations',
    parent: 'Covid Hospitalization',
    toolTip: null,
    mapTitle: null,
    mapSubTitle: null,
    colorAxis: colorAxisCasesWeekly,
    countyColorAxis: colorAxisCasesWeeklyHrr,
    legend: {
      enabled: false,
      title: {
        text: 'Weekly Hospitalizations',
      },
    },
  }

  const [states, setStates] = useState(undefined)

  const [getJsonData, setJsonData] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/us_hrr_values.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       // console.log(data)
       setJsonData(data);
      })
      .catch(function (err) {
        console.log(err, " error file load us_hrr_values ");
      });
  }, [1]);

  const { data: statesTodayData, error: stateTodayDataError } = useGeoFetch(
    hrrenddate,
    hrrenddate,
    'covid/Hospitalization',
    'hrr'
  )
  var hrrdate
  useEffect(() => {
    if (statesTodayData !== undefined) {      
      //console.log(statesTodayData)
      setStates(statesTodayData.response_data)
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesTodayData])
  var graphOptions = null
  if (states) {    
    hrrdate = Object.keys(states)[0]
    var mapHRRData = null
    if (states.geo_id == undefined) {
      const hrrDataForDisplayDate = Object.entries(states[hrrdate])
      mapHRRData = hrrDataForDisplayDate.map((county) => {
        return {
          value: county[1][mapOptions.value],
          code: county[0].toString(),
          identifier: county[0].toString(),
          ...county[1],
        }
      })
    } else {    
      const hrrDefaults = {}
      hrrDefaults[hrrdate] = HRRDefaults
      const hrrDataForDisplayDate = Object.entries(hrrDefaults[hrrdate])
      mapHRRData = hrrDataForDisplayDate.map((county) => {
        return {
          value: county[1][mapOptions.value],
          code: county[0].toString(),
          identifier: county[0].toString(),
          ...county[1],
        }
      })
    }

    if (mapHRRData !=undefined && mapHRRData !=null && (getJsonData !=undefined))
    {
      graphOptions = {
        title: {
          text: null,
        },
        subtitle: {
          text: mapOptions.mapSubTitle,
        },
        tooltip: {
          enabled: false,
        },
        chart: {
          backgroundColor: 'none',
          displayErrors: false,
        },
        legend: mapOptions.legend,
  
        credits: {
          enabled: false,
        },
        colorAxis: mapOptions.countyColorAxis,
  
        series: [
          {
            mapData: getJsonData,
            data: mapHRRData,
            joinBy: ['HRRNUM', 'geo_id'],
            borderColor: 'gray',
            borderWidth: 1,
            states: {
              select: {
                color: null,
                borderColor: 'gray',
              },
              hover: {
                borderColor: 'gray',
              },
            },
            shadow: false,
            cursor: 'pointer',
          },
        ],
        plotOptions: {
          map: {
            enableMouseTracking: false,
          },
        },
        mapline: {
          showInLegend: false,
          enableMouseTracking: false,
        },
      }
    }
    
  }
  const redirect = () => {
    history.push('forecasts/hrrmap')
  }
  if (stateTodayDataError || errorOccured) {
    const hrrDefaults = {}
    hrrDefaults[today] = HRRDefaults
    return (
      <GeoProvider startDate={today} endDate={today} displayDate={weekEndData}>
        <Map
          toolTipFormatter={null}
          activeCbsasData={null}
          statesData={hrrDefaults}
          cbsaData={null}
          mapOptions={mapOptions}
          enableMapNavigation={false}
          enableTooltip={false}
          enableMouseTracking={false}
          mapHeight={'40vh'}
          mapType="COVIDDASHBOARDMAP"
        />
      </GeoProvider>
    )
  }

  return (
    <GeoProvider startDate={today} endDate={today} displayDate={weekEndData}>
      {states  && (getJsonData !=undefined) ? (
        <div
          onClick={() => {
            history.push('/Forecasts/hrrmap')
          }}
          className={styles.base}
          title={'Click to view more details'}
        >
         {/* <img onClick={redirect} style={{ cursor: 'pointer' }} width="100%" height="70%" src={appurl} /> */}
          <HighchartsReact
            containerProps={{
              style: {
                height: '35vh',
                // width: '40vh',
                position: 'relative',
                display: 'flex',
                // marginLeft: '20vh',
              },
            }}
            constructorType="mapChart"
            highcharts={Highcharts}
            options={graphOptions}
            allowChartUpdate
            updateArgs={[true, true, false]}
          />
        </div>
      ) : (
        <EmptyMap mapHeight={'40vh'} />
      )}
    </GeoProvider>
  )
}

export default HrrMap
