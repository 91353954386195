import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import CountingMethod from './../../utils/getCountingMethod'

import Spinner from '../Loader/Spinner'

import getPieChartOptions from './getPieChartOptions'

import styles from './PieChartDisplay.module.scss'
import { useGeoState } from '../../components/GeoProvider'
if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const PieChartDisplay = ({
  entity,
  location,
  locationDisplay,
  startDate,
  endDate,
  options,
  toolTipFormatter,
  data,
  dataDisplayOptions,
  chartName,
  pieFor,
  height,
  countPatient,
}) => {
 

  const [trendNameSelect, setTrendNameSelect] = useState(
    dataDisplayOptions[0].label
  )
  const { claimsFiled } = useGeoState('')
  var finalData = []
  var med_data = ''
  var vacc_data = ''
  var phar_data = ''
  var data_Medical
  var vul = ''
  //console.log(data)
  if (chartName === 'chart1') {    
    med_data = JSON.parse(data.response_data)
  }
  if (chartName === 'chart2') {
    
    var phar_data =  JSON.parse(data.response_data)
  }
  if (chartName === 'chart3') {
    vacc_data = JSON.parse(data.response_data)
  }
  if (chartName === 'chart4') {
    data_Medical = JSON.parse(data.response_data)
  }

  if (chartName === 'samplePie' || chartName === 'populationPie') {
    var res = data.response_data;
    vul = res.filter(function (el) {
      return (
        el.demographic === 'F' || el.demographic === 'M' || el.demographic === 'U'
      )
    })
  }

  var chart_data = []
  var val_sum = 0
  if (chartName === 'chart1') {
    if (med_data!=null && med_data !==undefined)
    {
      med_data.sort((a, b) => (parseFloat(a.Record) < parseFloat(b.Record)) ? 1: -1);
    
    
    med_data.forEach((data_val) => {
      val_sum =  parseInt(data_val.Total)
    })
    med_data.forEach((data_val) => {
      var arr = []
      arr.push(data_val.PROC_CD)
      arr.push(parseFloat(((data_val.Record * 100) / val_sum).toFixed(2)))
      chart_data.push(arr)
    })
  }
  } else if (chartName === 'chart2') {
    if (phar_data!=null && phar_data !==undefined)
    {
    phar_data.sort((a, b) => (parseFloat(a.Record) < parseFloat(b.Record)) ? 1: -1);
    phar_data.forEach((data_val) => {
     // val_sum += parseInt(data_val.Record)
     val_sum = parseInt(data_val.Total)
    })
    phar_data.forEach((data_val) => {
      var arr = []
      arr.push(data_val.NDC_CD)
      arr.push(parseFloat(((data_val.Record * 100) / val_sum).toFixed(2)))
      chart_data.push(arr)
    })
  }
} else if (chartName === 'chart3') {
  if (vacc_data!=null || vacc_data !==undefined)
    {
    vacc_data.sort((a, b) => (parseFloat(a.Record) < parseFloat(b.Record)) ? 1: -1);
    vacc_data.forEach((data_val) => {
      val_sum += parseInt(data_val.Record)
    })
    vacc_data.forEach((data_val) => {
      var arr = []
      arr.push(data_val.Vaccine_Classification)
      arr.push(parseFloat(((data_val.Record * 100) / val_sum).toFixed(2)))
      chart_data.push(arr)
    })

    val_sum = claimsFiled
  } }
  else if (chartName === 'chart4') {
    if (data_Medical!=null || data_Medical !==undefined)
    {
    data_Medical.forEach((data_val) => {
      val_sum += data_val.Record
    })
    data_Medical.forEach((data_val) => {
      var arr = []
      arr.push(data_val.PROC_CD)
      arr.push(parseFloat(((data_val.Record * 100) / val_sum).toFixed(2)))
      chart_data.push(arr)
    })
  }} else if (chartName === 'samplePie') {
    vul.forEach((data_val) => {
      val_sum += data_val.samplePerc
    })
    vul.forEach((data_val) => {
      var arr = []
      arr.push(data_val.demographic)
      arr.push(parseFloat(((data_val.samplePerc * 100) / val_sum).toFixed(2)))
      chart_data.push(arr)
    })
    val_sum = countPatient
  } else if (chartName === 'populationPie') {
    vul.forEach((data_val) => {
      val_sum += data_val.populationPerc
    })
    vul.forEach((data_val) => {
      var arr = []
      arr.push(data_val.demographic)
      arr.push(
        parseFloat(((data_val.populationPerc * 100) / val_sum).toFixed(2))
      )
      chart_data.push(arr)
    })
    val_sum = countPatient
  } else {
    phar_data.forEach((data_val) => {
      val_sum += data_val.Record
    })
    phar_data.forEach((data_val) => {
      var arr = []
      arr.push(data_val.Vaccine_Classification)
      arr.push(parseFloat(((data_val.Record * 100) / val_sum).toFixed(2)))
      chart_data.push(arr)
    })
  }

  finalData.push({
    name: 'total_count',
    data: CountingMethod(val_sum, 'fixed'),
  })
  finalData.push({ name: 'data', data: chart_data })

  var hydratedData = []
  const chartOptions = getPieChartOptions(
    toolTipFormatter,
    hydratedData,
    trendNameSelect,
    finalData,
    pieFor,
    height
  )


  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default PieChartDisplay
