import React, { useState, useEffect } from 'react'
import styles from './VulCountyGraphPanel.module.scss'
import HorizontalBarChart from '../../components/HorizontalBarChart'
import HorizontalBarChartCustom from '../../components/HorizontalBarChartCustom'
import toolTipFormatter from '../../pages/FluVaccination/toolTipFormatter'
import dataDisplayOptions from '../../pages/FluVaccination/dataDisplayOptions'
import stateOptions from '../../components/Map/usStatesAll'
import { startDate, endDate } from '../../pages/FluVaccination/dates'
import AgeDistributionGraph from './AgeDistributionGraph'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import countingMethod from './../../utils/getCountingMethod'
import ErrorMessage from '../../components/Layout/ErrorMessage'
// import stateNamebyID from './../../utils/stateNamebyID'

import Select from 'react-select'
const VulCountyGraphPanel = ({ stateCode }) => {
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const { vulCount } = useGeoState()
  const [getCounty, setCounty] = useState('')
  const [getCountyPatient, setCountyPatient] = useState('')
  const [getFIPS, setFIPS] = useState(0)

  const [getStateVulnerabilityPerc, stateVulnerabilityPerc] = useState(0)
  const [getStatePatientCount, statePatientCount] = useState(0)
  const [getRender, setRender] = useState(true)
  const [getOptions, setItems] = useState({
    value: `0`,
    label: `Select county option`,
  })
  const [getCountydata, setCountydata] = useState('')
  const [getVulCountData, setVulCountData] = useState(0)

  //var getVulCountData = null
  // setFIPS(`getcountyfactor?fips=`)
  const handleSelect = (e) => {
    if (e.stateId !== '0') {
      setCounty(e.label)
      setFIPS(`getcountyfactor/${e.fips}`)
      setItems({ value: `${e.value}`, label: `${e.label}` })
    } else {
      setCounty('')
    }
  }

  const { data: getdata, error: getDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    `getcountydata/${stateCode}`
  )

  // if (getdata !==null &&  getdata!== undefined)
  // {
  //   setCountyddl(getdata)
  //   // console.log(getdata)
  // }
  const { data: getfactorData, error: getFactorDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    `getfactor/${stateCode}`
  )
  useEffect(() => {
    if (getdata !== null && getdata !== undefined) {
      if (getdata.response_code === 200) {
        setCountydata(getdata.response_data)
      } else {
        setErrorCode(getdata.response_code)
        setErrorMessage(getdata.response_message)
        setErrorSubject(getdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [getdata])
  // let renderView = false
  //let stateVulnerabilityPerc
  // let statePatientCount

  useEffect(() => {
    if (getfactorData !== null && getfactorData !== undefined) {
      if (getfactorData.response_code === 200) {        
        //setCountydata(getdata.response_data)
        Promise.all([
          setFIPS(0),
          setItems({ value: `0`, label: `Select county option` }),
          setCountyPatient(getfactorData.response_data),
          setVulCountData(
            getfactorData.response_data.vulnerabilityPatientCount[0]
          ),
          // getVulCountData = getfactorData.response_data.vulnerabilityPatientCount[0],
          stateVulnerabilityPerc(
            getfactorData.response_data.vulnerabilityPatientCount[0]
              .stateVulnerabilityPerc
          ),
          //stateVulnerabilityPerc = getVulCountData.stateVulnerabilityPerc,
          //statePatientCount = getVulCountData.statePatientCount,
          statePatientCount(
            getfactorData.response_data.vulnerabilityPatientCount[0]
              .statePatientCount
          ),
          setRender(true),
        ])
      } else {
        setErrorCode(getfactorData.response_code)
        setErrorMessage(getfactorData.response_message)
        setErrorSubject(getfactorData.response_subject)
        seterrorOccured(true)
        setRender(false)
      }
    }
  }, [getfactorData, stateCode])

  //  let data1 = stateNamebyID.cityInfo;
  //  let data = stateNamebyID['44']

  let cityInfo = {
    1: 'ALABAMA',
    2: 'ALASKA',
    AS: 'AMERICAN SAMOA',
    3: 'ARIZONA',
    4: 'ARKANSAS',
    5: 'CALIFORNIA',
    6: 'COLORADO',
    7: 'CONNECTICUT',
    8: 'DELAWARE',
    9: 'DISTRICT OF COLUMBIA',
    FM: 'FEDERATED STATES OF MICRONESIA',
    10: 'FLORIDA',
    11: 'GEORGIA',
    GU: 'GUAM',
    12: 'HAWAII',
    13: 'IDAHO',
    14: 'ILLINOIS',
    15: 'INDIANA',
    16: 'IOWA',
    17: 'KANSAS',
    18: 'KENTUCKY',
    19: 'LOUISIANA',
    20: 'MAINE',
    MH: 'MARSHALL ISLANDS',
    21: 'MARYLAND',
    22: 'MASSACHUSETTS',
    23: 'MICHIGAN',
    24: 'MINNESOTA',
    25: 'MISSISSIPPI',
    26: 'MISSOURI',
    27: 'MONTANA',
    28: 'NEBRASKA',
    29: 'NEVADA',
    30: 'NEW HAMPSHIRE',
    31: 'NEW JERSEY',
    32: 'NEW MEXICO',
    33: 'NEW YORK',
    34: 'NORTH CAROLINA',
    35: 'NORTH DAKOTA',
    MP: 'NORTHERN MARIANA ISLANDS',
    36: 'OHIO',
    37: 'OKLAHOMA',
    38: 'OREGON',
    PW: 'PALAU',
    39: 'PENNSYLVANIA',
    PR: 'PUERTO RICO',
    40: 'RHODE ISLAND',
    41: 'SOUTH CAROLINA',
    42: 'SOUTH DAKOTA',
    43: 'TENNESSEE',
    44: 'TEXAS',
    45: 'UTAH',
    46: 'VERMONT',
    VI: 'VIRGIN ISLANDS',
    47: 'VIRGINIA',
    48: 'WASHINGTON',
    49: 'WEST VIRGINIA',
    50: 'WISCONSIN',
    51: 'WYOMING',
  }
  if (getDataError || getFactorDataError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
      />
    )
  }
  // console.log(cityInfo[stateCode]);
  // const colourStyles = {
  //   control: (styles) => ({
  //     ...styles,
  //     backgroundColor: 'white',
  //     borderRadius: '0px',
  //   }),
  // }

  return (
    <>
      {getRender ? (
        <div className={styles.base}>
          <div>
            <div className={styles.countymapheader}>
              State Level Vulnerability
            </div>
            <div className={styles.maindivPerc}>
              <span className={styles.perc}>
                {countingMethod(getStateVulnerabilityPerc, 'fixed')}%
              </span>
              <div>
                of {cityInfo[stateCode]} of Population is at significant risk of
                COVID-19 related hospitalization
              </div>
            </div>
            <div className={styles.outputtitle}>
              Medical Factors impacting Output
            </div>
            <div className={styles.ratePanel}>
              <span>
                {cityInfo[stateCode]} - State
                <span>
                  Patient Count:{' '}
                  <strong>
                    {' '}
                    {countingMethod(getStatePatientCount, 'fixed')}{' '}
                  </strong>
                </span>
              </span>
              <HorizontalBarChartCustom
                toolTipFormatter={toolTipFormatter}
                options={stateOptions}
                dataDisplayOptions={dataDisplayOptions}
                chartName="getfactor"
                data={getCountyPatient}
              />
              <span className={styles.graphSource}>
                Source : <span>Medical and Pharmacy Claims Data</span>
              </span>
            </div>
          </div>

          <div>
            <div className={styles.countymapheader}>
              County Level Vulnerability{' '}
              <div className={styles.App}>
                {getCountydata && (
                  <Select
                    className="dropdown"
                    placeholder="Select Options"
                    options={getCountydata}
                    onChange={handleSelect}
                    value={getOptions}
                  />
                )}
              </div>
            </div>
            <div className={styles.maindivPerc}>
              {getCounty !== '' ? (
                <span className={styles.perc}>
                  {vulCount.countyVulnerabilityPerc}%
                </span>
              ) : null}
              {getCounty === '' ? (
                <div>
                  Select a county from the county dropdown to view County level
                  data
                </div>
              ) : (
                <div>
                  of {getCounty} Population is at significant risk of COVID-19
                  related hospitalization
                </div>
              )}
            </div>
            <div className={styles.healthPanel}>
              <span>
                County Data
                <span>
                  Patients Count <strong>{vulCount.patientCount}</strong>
                </span>
              </span>
              {getFIPS && (
                <HorizontalBarChart
                  toolTipFormatter={toolTipFormatter}
                  entity="vulnerability"
                  location={getFIPS}
                  locationDisplay="getcountyfactor"
                  startDate={startDate}
                  endDate={endDate}
                  options={stateOptions}
                  dataDisplayOptions={dataDisplayOptions}
                  chartName="countyFactor"
                  responseData={setCountyPatient}
                  datafill={true}
                />
              )}
              <span className={styles.graphSource}>
                Source : <span>Medical and Pharmacy Claims Data</span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <ErrorMessage
          errorCode="402"
          errorSubject="Data not available"
          errorMessage="Data not available"
        />
      )}

      {getVulCountData && getRender && (
        <AgeDistributionGraph
          stateCode={stateCode}
          getVulCountData={getVulCountData}
        />
      )}
    </>
  )
}

export default VulCountyGraphPanel
