const dataDisplayOptions = [
  {
    value: 'Respiratory Syncytial Virus (RSV)',
    label: 'Respiratory Syncytial Virus (RSV)',
  },
  { value: 'Non-Influenza Pneumonia', label: 'Non-Influenza Pneumonia' },
  { value: 'Pure Flu', label: 'Pure Flu' },
  {
    value: 'Human Metapneumovirus (HMPV)',
    label: 'Human Metapneumovirus (HMPV)',
  },
]

export default dataDisplayOptions
