import React, { useEffect } from 'react'
import Slider from 'rc-slider'
import { useGeoState } from '../GeoProvider'
import spreadOutObject from '../../utils/spreadOutObject'
import styles from './Timeline.module.scss'
import 'rc-slider/assets/index.css'
import '../../styles/colors.scss'
import toTitleCase from './../../utils/toTitleCase'
import moment from 'moment'

const TimelineSlider = ({
  days,
  latestPositionString,
  slotString,
  slotTimeInSeconds,
  markCounts,
  curIndex,
  setCurIndex,
  relativeSlots = true,
}) => {
  const {
    loadedDate,
    UHCStateLoadedDate,
    UHCCountyLoadedDate,
    displayDate,
    setDisplayDate,
    setDisplayWeek,
    setTimelineIsPaused,
    mapOptions,
    setIndicatorName,
  } = useGeoState()

  const getPositionDate = (value) => {
    switch (mapOptions['parent']) {
      case 'UHC Indicators':
        if (value === 'state' || value === 'metro') {
          return UHCStateLoadedDate
        } else {
          return UHCCountyLoadedDate
        }
      default:
        return loadedDate
    }
  }
  const maxDayIndex = days.length - 1

  const timelineIndexMarksDisplay = (cur) => {
    return `${slotString} ${cur.epi_period_week}`
  }

  const timelineRelativeIndexMarksDisplay = (displayDate) => {
    // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component
    const timeSpan =
      (new Date(displayDate) - new Date(loadedDate)) / slotTimeInSeconds

    // TODO: can break this into a common function for the display date and this component to use!
    if (timeSpan === 0) {
      return latestPositionString
    } else {
      return `${moment(displayDate).format('MMM DD,YYYY')}`
    }
  }

  const indexWithDate = days.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.index]: relativeSlots
        ? timelineRelativeIndexMarksDisplay(cur.date)
        : timelineIndexMarksDisplay(cur),
    }),
    {}
  )

  const date = getPositionDate()
  const loadedDay = days.filter((w) => w.date === date)[0]
  const marks = spreadOutObject(
    indexWithDate,
    markCounts,
    loadedDay.index,
    latestPositionString
  )

  function handleTimelineChange(value) {
    setCurIndex(value)
    setDisplayDate(days[value].date)
    setDisplayWeek(days[value].epi_period_week)
    let valueObj = days[value][Object.keys(days[value])[2]]
    setIndicatorName(toTitleCase(valueObj.data_type + 's'))
  }

  useEffect(() => {
    const day = days.filter((w) => w.date === displayDate)[0]
    setCurIndex(day.index)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayDate])

  useEffect(() => {
    setDisplayWeek(days[curIndex].epi_period_week)
    setDisplayDate(days[curIndex].date)
    if (curIndex >= maxDayIndex) {
      setTimelineIsPaused(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curIndex])


  return (
    <Slider
      className={styles.rcSlider}
      min={0}
      max={maxDayIndex}
      marks={marks}
      value={curIndex}
      onChange={handleTimelineChange}
      railStyle={{ backgroundColor: '#CBCCCD', height: 8 }}
      trackStyle={{ backgroundColor: '#0C55B8', height: 8 }}
      handleStyle={{
        borderColor: '#0C55B8',
        height: 25,
        width: 25,
        marginLeft: 3,
        marginTop: -9,
        borderWidth: '2px',
        ':active': {
          boxShadow: '0 0 5px #316bbe',
        },
      }}
      dotStyle={{
        borderWidth: '.4px',
        height: 10,
        width: 10,
        bottom: -5,
        borderColor: '#4B4DAF',
      }}
      activeDotStyle={{ borderColor: '#002677' }}
      // handle={handle}
    />
  )
}

export default TimelineSlider
