import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Layout from '../../../components/Layout'
import Flex from '../../../components/Flex'
import { useAuthState } from '../../../components/AuthProvider'
import Content from '../../../components/Content'
import Spinner from '../../../components/Loader/Spinner'
import ButtonList from '../../../components/ButtonList'
import LinkButton from '../../../components/LinkButton'
import DataTable from '../../../components/DataTableProfile'
import { useMessageToast } from '../../../components/MessageProvider'
import Modal from '../../../components/ModalPopup'
import Button from '../../../components/Button'

import styles from './SystemNotifications.module.scss'
import activityLog from '../../../utils/activityLog'
import AdminHeader from '../AdminHeader'
import ButtonAddUser from '../ButtonAddUser'
import AddEditSystemNotifications from '../../Admin/SystemNotifications/AddEditSystemNotification'
import ViewSystemNotification from '../../Admin/SystemNotifications/ViewSystemNotification'
const countStyle=
{
    styleTxtCount:{
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22.78px',
    color: 'rgb(50, 51, 52)',
    }
}
const SystemNotifications = (countSubs) => {
    const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
    const systemNotificationApiUrl = `${clientConfigApiDomain}/admin/systemnotifications`
    const systemNotificationApiUrlGet = `${clientConfigApiDomain}/systemnotifications`

    const history = useHistory()
    const { user } = useAuthState()
    const { id } = useParams()
    const [getFlgPopup, setFlgPopup] = useState(false)
    const cardStyles = {
       mapPopup: {
        marginLeft: '738px',
        marginTop: '-252px',
         height: 'auto',
         width: '480px',     
        zIndex: '2',
        position: 'absolute',      
        backgroundColor: '#ffffff',
        border: '0.0625rem solid #e0e0e0',
        boxShadow: '2px 2px 9px 0 rgba(0, 0, 0, 0.25)',
        },
      } 

      const location = useLocation()
      const hash = location.hash
    const [systemNotificationData, setSystemNotificationData] = useState([])
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
    const toast = useMessageToast()
    const [selectedSystemNotification, setSelectedSystemNotification] =
        React.useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)

    const clickableCell = ({
        cell: {
            value,
            row: { original: selectedSystemNotification },
        },
    }) => {
        const onClick = () => {
            history.push({
                pathname: `/admin/systemnotifications/${selectedSystemNotification.id}`,
            })
        }

        return (
            <span className={styles.cell} onClick={onClick}>
                {value}
            </span>
        )
    }

    const handleDeleteSuccess = (systemNotification) => {
        toast.success(`Successfully deleted System Notification.`)
        setSystemNotificationData(
            systemNotificationData.filter((s) => s !== systemNotification)
        )
    }

    const handleDeleteError = (systemNotification) => {
        toast.error(
            `Error when attempting to delete System Notification : ${systemNotification.id}.`
        )
    }

    const onDeleteCancelClick = () => {
        setShowDeleteDialog(false)
        setSelectedSystemNotification({})
    }

    const onDeleteSystemNotification = () => {
        fetch(`${systemNotificationApiUrl}/${selectedSystemNotification.id}`, {
            method: 'delete',
        })
            .then((response) => {
                if (!response.ok) throw Error('Failed to delete System Notification')
                handleDeleteSuccess(selectedSystemNotification)
            })
            .catch(() => {
                handleDeleteError(selectedSystemNotification)
            })
        setShowDeleteDialog(false)
    }

    React.useEffect(() => {
        const fetchSystemNotifications = async () => {
            const response = await fetch(`${systemNotificationApiUrlGet}/`)
            const data = await response.json()
            //console.log(data)
            setSystemNotificationData(data)
        }

        if (!hasLoaded) {
            setIsLoading(true)
            fetchSystemNotifications()
            setIsLoading(false)
            setHasLoaded(true)
        }

        var url = window.location.href
        var name = ''
        if (url.indexOf('systemnotifications') !== -1) name = 'System Notifications'

        var logObject = {
            dashboard_url: url,
            dashboard_name: name,
            indicator_name: name,
            geo_name: '',
            geo_type: '',
            user_name: user.username,
            email: user.email,
        }
        activityLog(logObject)
    }, [hasLoaded, systemNotificationApiUrl])

    const actionCell = ({
        cell: {
            row: { original: selectedSystemNotification },
        },
    }) => {
        const onDetailsClick = () => {
            history.push(
                `/admin/systemnotifications/${selectedSystemNotification.id}`
            )
        }

        const onEditClick = () => {
          history.push(
            `/admin/systemnotifications/${selectedSystemNotification.id}/edit`
          )
        }

        const onDeleteClick = () => {
            setSelectedSystemNotification(selectedSystemNotification)
            setShowDeleteDialog(true)
        }

        return (
            <ButtonList className={styles.icons}>
                <LinkButton icon="view" onClick={onDetailsClick}>
                    Details
                </LinkButton>
                 {/* <LinkButton icon="edit" onClick={onEditClick}>
          Edit
        </LinkButton> */}
                <LinkButton icon="remove" onClick={onDeleteClick}>
                    Delete
                </LinkButton>
            </ButtonList>
        )
    }

    const columns = [
        {
            Header: 'Message',
            accessor: 'message',
            Cell: clickableCell,
        },
        {
            Header: 'Start Date',
            accessor: (row) => row.startDate.split('T')[0],
        },
        {
            Header: 'End Date',
            accessor: (row) => row.endDate.split('T')[0],
        },
        {
            Header: 'Updated By',
            accessor: 'updatedBy',
        },
        {
            Header: 'Updated At',
            accessor: (row) => row.updatedAt.split('T')[0],
        },
        {
            Header: '',
            accessor: 'actions',
            disableFilters: true,
            disableSortBy: true,
            Cell: actionCell,
        },
    ]

    return (
        <Layout>
            <Content>
                <div className={styles.base}>
                    <div className={styles.body}>
                        <AdminHeader />
                        <div className={styles.content}>
                            <span style={countStyle.styleTxtCount}>{systemNotificationData.length > 0 ? systemNotificationData.length :"" 
                            } &nbsp;&nbsp;System Notifications
                            </span>
                            <ButtonAddUser buttonText={'Notifications'}/>
                            <p style={{width:'65%'}}>
                                Adding System Notifications informs a logged in user of upcoming system updates on the dashboard.
                                Select a System Notifications to manage its respective properties.1
                            </p>
                        </div>
                        {id !== undefined  && (
                                <div style={cardStyles.mapPopup}>
                                    <ViewSystemNotification />
                                </div>
                            )}
                            {  hash && (
                                <div style={cardStyles.mapPopup}>
                                    <AddEditSystemNotifications />
                                </div>
                            )}
                        <Flex gutter={3}>
                            <Flex.Item span={12}>
                                <div className={styles.base}>
                                    {/* <ButtonList spacing={2} style={{ margin: '1rem 0' }}>
                                        {user.role === 'Admin' ? (
                                            <LinkButton
                                                icon="add"
                                                onClick={() => {
                                                    history.push('/adminpage/systemnotifications/add')
                                                }}
                                            >
                                                Add System Notifications
                                            </LinkButton>
                                        ) : null}
                                    </ButtonList> */}
                                    {isLoading ? (
                                        <Spinner />
                                    ) : hasLoaded ? (
                                        <DataTable
                                            columns={columns}
                                            data={systemNotificationData}
                                            hasSorting
                                            hasPagination
                                        />
                                    ) : null}
                                </div>
                            </Flex.Item>
                        </Flex>
                    </div>
                </div>
            </Content>

            <Modal open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                    <h4>Deleting System Notification</h4>
                    Are you sure you want to delete System Notification:{' '}
                    {selectedSystemNotification.message}?
                </div>
                <ButtonList>
                    <Button primary type="submit" onClick={onDeleteSystemNotification}>
                        Confirm
                    </Button>
                    <Button onClick={onDeleteCancelClick}>Cancel</Button>
                </ButtonList>
            </Modal>
        </Layout>
    )
}

export default SystemNotifications
