import React from 'react'
import moment from 'moment'
import styles from './FluIndicatorTable.module.scss'
import LineChart from '../../../components/DateLineChart'
import dataDisplayOptions from './dataDisplayOptions'
// import Icon from './../../../components/Icon'
import RiskIcon from './../../../components/RiskIcon'
import getPositiveMethod from './../../../utils/getCountingMethod'
import { useGeoState } from './../../../components/GeoProvider'
const IndicatorTable = (data, val) => {
  let finalData = data.data
  const allData = data.alldata

  //Hydrate the data for line graph
  let allForecastData = []
  let allForecastData1 = []
  const values = Object.values(allData)
  const keys = Object.keys(allData)
  const newValues = values.map((val, index) => {
    const valObj = Object.entries(val)
    return { data_val: valObj, data_dt: keys[index] }
  })

  newValues.forEach((dt) => {
    dt.data_val.forEach((f) => {
      allForecastData.push({
        identifier: f[0],
        ...f[1],
        week_dt: dt.data_dt,
      })
    })
  })

  allForecastData.map((val, index) => {
    const valObj = Object.entries(val)
    valObj.forEach((vl) => {
      if (vl[0] === data.val)
        if (vl[1].weekDate >= data.dateSelection)
          allForecastData1.push({
            data_date: vl[1].weekDate,
            data_value: vl[1].pointValue,
          })
    })
    return allForecastData1
  })
  //Hydrate the data for line graph
  return (
    <div className={styles.subbase}>
      <div
        style={{
          paddingTop: '10px',
          display: 'inline-flex',
          width: '28%',
          whiteSpace: 'nowrap',
          verticalAlign: 'top',
        }}
        title={finalData.indicator}
      >
        <p className={styles.valuePara}>
          <a >{finalData.indicator}</a>
        </p>
      </div>
      <div
        style={{
          paddingTop: '10px',
          display: 'inline-flex',
          width: '7%',
          justifyContent: 'flex-middle',
          verticalAlign: 'top',
        }}
      >
        <p className={styles.valuePara}>{finalData.pointValue.toFixed(2)}%</p>
      </div>
      <div
        style={{
          paddingTop: '10px',
          display: 'inline-flex',
          width: '21%',
          justifyContent: 'flex-start',
          verticalAlign: 'top',
        }}
      >
        <p className={styles.valuePara}>
          <RiskIcon
            size="14px"
            dataValue={finalData.changePercentage.toFixed(2)}
          />
          {/* {finalData.changeLevel === 1 ? (
            <Icon size="14px" type="arrow_wtail_bold_up" color="red" />
          ) : (
            <Icon size="14px" type="arrow_wtail_bold_down" color="green" />
          )} */}
          {'  '}
          {'  '}
          {getPositiveMethod(finalData.changePercentage.toFixed(2))}%{' from '}
          {moment(finalData.lastWeekDate).format('MMM DD, YYYY')}
        </p>
      </div>
      <div
        style={{
          display: 'inline-flex',
          width: '20%',
          justifyContent: 'flex-end',
          // marginLeft: '5%',
        }}
      >
        <LineChart
          dataDisplayOptions={dataDisplayOptions}
          height={'60px'}
          type={'sparkline'}
          data={allForecastData1}
        />
      </div>
    </div>
  )
}
const FluIndicatorTable = (data, nationalStats) => {
  const { toDate } = useGeoState()
  let mapNatsData = null
  const historicalFromDate = moment(toDate, 'DD-MM-YYYY')
    .add(-60, 'days')
    .format('YYYY-MM-DD')
  var natData = data.nationalStats
  const natsDataForDisplayDate = Object.entries(
    natData[Object.keys(natData).pop()]
  )
  //const history_date_from = Object.keys(natData)[0]
  const history_date_to = Object.keys(natData)[Object.keys(natData).length - 1]

  mapNatsData = natsDataForDisplayDate.map((nat) => {
    return {
      value: nat[1],
      name: nat[0],
      ...nat[1],
    }
  })
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <span className={styles.headerSpan}>
          Respiratory Indicators Comparison Table
        </span>
        <div
          style={{ padding: '1rem 1rem', paddingLeft: '0', paddingRight: '0' }}
        >
          <div style={{ display: 'inline-flex', width: '28%' }}>
            <p className={styles.headerPara}>Indicator</p>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '7%',
              justifyContent: 'flex-start',
            }}
          >
            <p className={styles.headerPara}>Value</p>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '22%',
              justifyContent: 'flex-start',
            }}
          >
            <p className={styles.headerPara}>
              Relative Change to Previous Week
            </p>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '36%',
              justifyContent: 'flex-start',
              // marginLeft: '5%',
            }}
          >
            <p className={styles.headerPara}>
              Historical Trend -{' '}
              <span>
                {moment(historicalFromDate).format('MM/DD/YYYY')} to{' '}
                {moment(history_date_to).format('MM/DD/YYYY')}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ height: '100%' }}>
        {dataDisplayOptions.map((val) => {
          return (
            <IndicatorTable
              data={mapNatsData[0].value[val.value]}
              alldata={natData}
              val={val.value}
              dateSelection={historicalFromDate}
            />
          )
        })}
      </div>
    </div>
  )
}
export default FluIndicatorTable
