import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Layout from '../../../components/Layout'
import { useAuthState } from '../../../components/AuthProvider'
import Content from '../../../components/Content'
import DataTable, { DropDownListFilter } from '../../../components/DataTableProfile'
import ButtonList from '../../../components/ButtonList'
import LinkButton from '../../../components/LinkButton'
import { useMessageToast } from '../../../components/MessageProvider'
import Modal from '../../../components/ModalPopup'
import Button from '../../../components/Button'
import activityLog from '../../../utils/activityLog'
import styles from '../../../pages/Admin/Users/Users.module.scss'
import AdminHeader from '../AdminHeader'
import CountUser from '../CountUser'
import ButtonAddUser from '../ButtonAddUser'
import AddEditUser from '../../Admin/Users/AddEditUser'
const Users = () => {
   
    const { id } = useParams()
    const [getFlgPopup, setFlgPopup] = useState(false)
    const cardStyles = {
       mapPopup: {
        marginLeft: '738px',
        marginTop: '-252px',
         height: 'auto',
         width: '480px',     
        zIndex: '2',
        position: 'absolute',      
        backgroundColor: 'white',
        border: '0.0625rem solid #e0e0e0',
        boxShadow: '2px 2px 9px 0 rgba(0, 0, 0, 0.25)',
        },
      } 

      const location = useLocation()
      const hash = location.hash
      
    //   if (hash) {
    //    // setFlgPopup(true)
    //   }
    const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
    const userApiUrl = `${clientConfigApiDomain}/admin/users`

    const history = useHistory()
    const { user } = useAuthState()

    const toast = useMessageToast()

    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)

    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
    const [selectedUser, setSelectedUser] = React.useState({})

    const clickableCell = ({
        cell: {
            value,
            row: { original: selectedUser },
        },
    }) => {
        const onViewClick = () => {
            history.push(`/admin/users/${selectedUser.userId}`, [users.id])
        }

        return (
            <span className={styles.cell} onClick={onViewClick}>
                {selectedUser.lastName}, {selectedUser.firstName}
            </span>
        )
    }

    const timeFormatCell = ({
        cell: {
            value,
            row: { original: selectedUser },
        },
    }) => {
        const moment = require('moment-timezone')

        return (
            <span>
                {moment(selectedUser.lastLogin)
                    .tz(user.preferences.timeZone)
                    .format('L, LTS z')}
            </span>
        )
    }

    const handleDeleteSuccess = (user) => {
        toast.success(
            `Successfully deleted user: ${user.last_name}, ${user.first_name}.`
        )
        setUsers(users.filter((u) => u !== user))
    }

    const handleDeleteError = (user) => {
        toast.error(
            `Error when attempting to delete user: ${selectedUser.lastName}, ${selectedUser.firstName}.`
        )
    }

    const onDeleteCancelClick = () => {
        setShowDeleteDialog(false)
        setSelectedUser({})
    }

    const onDeleteUser = () => {
        fetch(`${userApiUrl}/${selectedUser.id}/`, { method: 'delete' })
            .then((response) => {
                if (!response.ok) throw Error('Failed to delete user')
                handleDeleteSuccess(selectedUser)
            })
            .catch(() => {
                handleDeleteError(selectedUser)
            })
        setShowDeleteDialog(false)
    }

    const actionCell = ({
        cell: {
            value,
            row: { original: selectedUser },
        },
    }) => {
        const onDetailsClick = () => {
            history.push(`/admin/users/${selectedUser.userId}`)
        }

        const onEditClick = () => {
            history.push(`/admin/users/${selectedUser.userId}/edit`)
        }

        // const onDeleteClick = () => {
        //   setSelectedUser(selectedUser)
        //   setShowDeleteDialog(true)
        // }

        const isVisible = (selectedUser) => {
            return user.role === 'Admin' && selectedUser.roleName !== 'Admin'
        }

         const me = user.userid === selectedUser.userId
        return (
            <ButtonList className={styles.icons}>
                {/* <LinkButton icon="view" onClick={onDetailsClick}>
                    Details
                </LinkButton> */}
                {isVisible(selectedUser) && (
                    <LinkButton icon="edit" onClick={onEditClick}>
                        {/* Edit */}
                    </LinkButton>
                )}
                {/* {!me && isVisible(selectedUser) && (
          <LinkButton icon="remove">
            Delete
          </LinkButton>
        )} */}
            </ButtonList>
        )
    }

    const userCsvColumns = [
        {
            Header: 'Last Name',
            accessor: 'lastName',
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
        },
        {
            Header: 'User Name',
            accessor: 'username',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Phone',
            accessor: 'phone',
        },
        {
            Header: 'Role',
            accessor: 'roleName',
        },
        {
            Header: 'Last Login',
            accessor: 'lastLogin',
        },
    ]

    const userColumns = [
        {
            Header: 'Name',
            accessor: (row) => row.lastName + row.firstName,
            Cell: clickableCell,
            sortType: (a, b) => {
                return `${a.values.lastName}, ${a.values.firstName}`.toLowerCase() >
                    `${b.values.lastName}, ${b.values.firstName}`.toLowerCase()
                    ? 1
                    : -1
            },
        },
        {
            Header: 'User Name',
            accessor: 'username',
            // sortType: (a, b) => {
            //     return `${a.values.username} ? ${a.values.username} : 'NA'`.toLowerCase() >
            //         `${b.values.username} ? ${b.values.username} : 'NA'`.toLowerCase()
            //         ? 1
            //         : -1
            // },           
        },
        {
            Header: 'Email',
            accessor: 'email',
            // sortType: (a, b) => {
            //     return a.values.email.toLowerCase() > b.values.email.toLowerCase()
            //         ? 1
            //         : -1
            // },
        },
        {
            Header: 'Phone',
            accessor: 'phone',
            // sortType: (a, b) => {
            //     return `${a.values.phone} ? ${a.values.phone} : 'NA'`.toLowerCase() >
            //         `${b.values.phone} ? ${b.values.phone} : 'NA'`.toLowerCase()
            //         ? 1
            //         : -1
            // },
        },
        {
            Header: 'Role',
            accessor: 'roleName',
            Filter: DropDownListFilter,
            filter: 'equals',
        },
        {
            Header: 'Last Login',
            accessor: 'lastLogin',
            Cell: timeFormatCell,
            // sortType: (a, b) => {
            //     return a.values.lastLogin.toLowerCase() >
            //         b.values.lastLogin.toLowerCase()
            //         ? 1
            //         : -1
            // },
        },
        {
            Header: '',
            accessor: 'actions',
            disableFilters: true,
            disableSortBy: true,
            Cell: actionCell,
        },
    ]

    useEffect(() => {
        const getUsers = async () => {
            const response = await fetch(`${userApiUrl}/`)
            const data = await response.json()          
            //console.log(data.length)
            setUsers(data)
        }
        if (!hasLoaded) {
            setIsLoading(true)
            getUsers()
            setIsLoading(false)
            setHasLoaded(true)
        }
        var url = window.location.href
        var name = ''
        if (url.indexOf('users') !== -1) name = 'Users'

        var logObject = {
            dashboard_url: url,
            dashboard_name: name,
            indicator_name: name,
            geo_name: '',
            geo_type: '',
            user_name: user.username,
            email: user.email,
        }
        activityLog(logObject)
    }, [
        userApiUrl,
        setIsLoading,
        setHasLoaded,
        hasLoaded,
        user.clients,
        user.role,
        user.orgIds,
    ])

    return users.length > 0 && (
        <Layout>
            <Content>
                <div className={styles.base}>
                    <div className={styles.body}>
                        <AdminHeader />
                        <div className={styles.content}>
                            <CountUser userCount={users.length} />
                            <ButtonAddUser buttonText={'User'}/>
                        </div>
                        <div style={{ marginTop: '45px' }}>
                            {id !== undefined  && (
                                <div style={cardStyles.mapPopup}>
                                    <AddEditUser />
                                </div>
                            )}
                            {   hash && (
                                <div style={cardStyles.mapPopup}>
                                    <AddEditUser />
                                </div>
                            )}
                          {users && (
                            <DataTable
                                columns={userColumns}
                                csvColumns={userCsvColumns}
                                data={users}
                                hasPagination
                                hasFiltering
                                hasSorting
                            />
                          )}  
                        </div>
                       

                    </div>
                </div>
            </Content>
            <Modal open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
                <div style={{ marginBottom: '1rem' }}>
                    Are you sure you want to delete user: {selectedUser.lastName},{' '}
                    {selectedUser.firstName}?
                </div>
                <ButtonList>
                    <Button primary type="submit" onClick={onDeleteUser}>
                        Confirm
                    </Button>
                    <Button onClick={onDeleteCancelClick}>Cancel</Button>
                </ButtonList>
            </Modal>
        </Layout>
    )
}

export default Users
