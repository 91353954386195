import React from 'react'
import DatePicker from 'react-datepicker'
import styles from './Filter.module.scss'
import dataDisplayOptions from './dataDisplayOptions'
import regionDisplayOptions from './regionDisplayOptions'
import { useGeoState } from './../../../components/GeoProvider'
import Select from 'react-select'

import Icon from './../../../components/Icon'

const Filter = () => {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className={styles.calenderDiv} onClick={props.onClick} ref={ref}>
        <Icon size="18px" type="calendar" color="grey" />
        <label className={styles.calenderLabel}>
          {props.value || props.placeholder}
        </label>
        {/* <TodayIcon onClick={props.onClick} /> */}
      </div>
    )
  })
  const {
    setIndicatorName,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    // locationCode,
    setLocationName,
    setLocationCode,

    setLocationType,
  } = useGeoState()

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      paddingLeft: 10,
      paddingTop: 2,
    }),
  }
  return (
    <div className={styles.base}>
      <div className={styles.datasetPanel}>
        <span>INDICATORS</span>
        <div>
          <Select
            styles={customStyles}
            defaultValue={dataDisplayOptions[0]}
            options={dataDisplayOptions}
            onChange={(opt) => {
              setIndicatorName(opt.value)
            }}
          />
        </div>
      </div>
      <div className={styles.regionPanel}>
        <span>REGIONS</span>
        <div>
          <Select
            styles={customStyles}
            defaultValue={regionDisplayOptions[0]}
            options={regionDisplayOptions}
            onChange={(opt) => {
              setLocationName(opt.label)
              setLocationCode(opt.value)
              setLocationType(opt.locationtype)
            }}
          />
        </div>
      </div>
      <div className={styles.fromPanel}>
        <span>FROM DATE</span>
        <div>
          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            customInput={<CustomInput />}
          />
        </div>
      </div>
      <div className={styles.toPanel}>
        <span>TO DATE</span>
        <div>
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            customInput={<CustomInput />}
          />
        </div>
      </div>
      <div className={styles.noPanel}></div>
    </div>
  )
}
export default Filter
